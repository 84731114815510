import * as React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {
  ConnectedRouter,
  routerMiddleware
} from 'connected-react-router';
import { history, initialState, PFXStorage, PublicLogin /*, PublicLogout */ } from './Root';
import { createStore, applyMiddleware } from 'redux';

import { thunk, ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { PFXMiddleWare } from './redux/middleware';
import { routedAppReducer } from './redux/actionReducers';
import { Msal2Auth } from './authentication/Msal2Auth';
import * as styling from './styling/styling';

export const enableServiceWorker = false;

declare global {
  interface String {
    format(...replacements: string[]): string;
  }
}

//Also fixes unused import warning
if (!React) {
  console.debug('React missing');
}

window.auth = new Msal2Auth(
  window.ProjectFlowX.tenantId,
  window.ProjectFlowX.azureAppId,
  window.location.origin + PublicLogin.to,
  window.user,
  window.ProjectFlowX.coreflow.resourceId,
  window.ProjectFlowX.azureAuthority
);

if (window.auth.isCallback(window.location.hash)) {
  // we're back from O365 auth with token stuff in hash.. let auth handle that..
  window.auth.handleWindowCallback(window.location.hash);
} else {


  (window.auth as Msal2Auth).msalInstance.handleRedirectPromise().then(token => {
    if (token) {
      localStorage.setItem(
        PFXStorage.LOGIN_ACTIVEUSER,
        token.account.username
      );
    }

    window.user = window.auth.getUser(localStorage.getItem(PFXStorage.LOGIN_ACTIVEUSER));

    if (window.user) {
      localStorage.setItem(
        PFXStorage.LOGIN_ACTIVEUSER,
        window.user.userName
      );
    }


    if (!window.user) {
      // Start O365 auth process
      window.auth.loginRedirect();
    } else {
      // Initialize styling
      styling.setDefaultFluentUITheme();
      styling.initializeIconAssets();

      // create store
      window.store = createStore(
        routedAppReducer(history),
        { appReducer: initialState },
        composeWithDevTools(
          // seems to crash quite often.. wait it out: https://github.com/reduxjs/redux-devtools/issues/391

          applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            PFXMiddleWare,
            thunk as ThunkMiddleware
          )
        )
      );

      // Render main PFX app
      render(
        <Provider store={window.store}>
          <ConnectedRouter history={history}>
            <App /* history={history} */ />
          </ConnectedRouter>
        </Provider>,
        document.getElementById('root') as HTMLElement
      );
      if (!enableServiceWorker) {
        serviceWorkerRegistration.unregister();
      } else {
        serviceWorkerRegistration.register(
          {
            onUpdate: registration => {
              // implementing skipWaiting, https://stackoverflow.com/questions/52904430/how-to-implement-skipwaiting-with-create-react-app

              const waitingServiceWorker = registration.waiting

              if (waitingServiceWorker) {
                waitingServiceWorker.addEventListener("statechange", event => {
                  // fix for https://github.com/microsoft/TypeScript/issues/37842
                  if ((event.target as ServiceWorker).state === "activated") {
                    window.location.reload()
                  }
                });
                waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
              }
            }
          }
        );
      }

    }
  }).catch(error => {
    console.error('AUTH Error', error);
    window.location.href = window.location.origin;
  });
}
