import { PanelType } from '@fluentui/react/lib/Panel';
import { IFilter } from 'src/components/CoreFlow/CoreFlow';

// import { ComponentClass, FunctionComponent } from 'react';

// Also update exported columns in CoreFlow/AdministrationPages/CoreFlowConfiguration.aspx?tool=OrgWS1&cf_id=PFX_Navigation

export interface PFXComponentProps {
  pageId?: string;
  composition?: string;
  reloadOnChange?: boolean; // path reload
  updateOnChange?: boolean; // component update
  reloadOnNavigateToSelf?: boolean;
  formType?: string;
  showSpinner?: boolean;
  skipDialog?: boolean;
  filter?: IFilter;
  newFormType?: string;
  contextWebOverride?: string;
  onCancel?: () => void;
  onSave?: () => void;
  isDriveBrowser?: boolean;
  noScroll?: boolean;
  fullPageLayout?: boolean;
  ganttV3Layout?: boolean;
  disableLightDismiss?: boolean;
  disableUnsavedCheck?: boolean;
  noHeader?: boolean;
  wantsLayoutChange?: boolean;
  dismissFilters?: string[];
}

export enum PFXMenuItemType {
  navigation = 'navigation',
  externalLink = 'externalLink',
  action = 'action',
  divider = 'divider',
  inline = 'inline',
}

export enum PFXContentPlacement {
  HELPPANEL_TOP = 'HELPPANEL_TOP',
  HELPPANEL_NEWTAB = 'HELPPANEL_NEWTAB',
  TOPPANEL_RIGHT = 'TOPPANEL_RIGHT',
  PAGE_TOP = 'PAGE_TOP',
  PAGE_BOTTOM = 'PAGE_BOTTOM',
  MESSAGEBAR = 'MESSAGEBAR',
  ACTIONPANEL_TOP = 'ACTIONPANEL_TOP',
  ACTIONPANEL_BOTTOM = 'ACTIONPANEL_BOTTOM',
}

export interface PFXExternalLinkProps {
  url: string;
  target?: string;
  features?: string;
}

export default interface PFXMenuItem {
  title: string;
  to?: string;
  menu?: string;
  scope?: string;
  component?: string; // ComponentClass | FunctionComponent;
  componentProps?: PFXComponentProps;
  subItems?: PFXMenuItem[];
  iconClass?: string;
  noNavigation?: boolean;
  hidden?: boolean;
  ParentID?: string;
  ID?: string;
  itemType?: PFXMenuItemType;
  redirectPath?: string;
  required?: boolean;
  panelWidth?: string;
  panelType?: PanelType;
  hasCustomContent?: string;
  externalLinkProps?: PFXExternalLinkProps;
}
