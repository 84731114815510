import { Icon } from '@fluentui/react/lib/Icon';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { PFXState } from 'src/Root';
import { PFXLocalizedTerms, PFXShared } from 'src/api/localizationApi';
import { PFXWeb } from 'src/api/webApi';
import { PFXUser } from 'src/models/PFXUser';
import * as types from 'src/redux/actionConstants';
import { clearAlert, closePanelAndRestoreAppFocus } from 'src/redux/actionCreators';
import { PFXComponentFactory } from 'src/utils/PFXComponentFactory';
import { PFXHelpers } from 'src/utils/PFXHelpers';
import PFXMenuItem, { PFXContentPlacement } from '../../models/PFXMenuItem';
import { AppType, IAppEditState, ToolType, cfx_web_ns, getProcessingApp, getUnsavedApp } from '../CoreFlow/CoreFlow';
import CoreFlowAppBase from '../CoreFlow/CoreFlowAppBase';
import { PFXDialog } from './SimpleDialog';
import actionPanelStyles from './action-panel/actionPanelStyles';
import * as styling from 'src/styling/styling';
import { PFXAppLayoutMode, PFXRenderZone } from 'src/styling/layout';

declare var cfx_web: typeof cfx_web_ns;

interface Props {
  contextWeb: PFXWeb;
  contextUser: PFXUser;
  lcid: number;
  panelItem: PFXMenuItem;
  onClose?: (checkUnsavedState?: boolean) => void;
  terms: PFXLocalizedTerms;
  layoutMode?: PFXAppLayoutMode;
}

export class ActionPanel extends React.Component<Props> {
  private helpText: string;
  private focusedBefore: HTMLElement;

  constructor(props: Props) {
    super(props);
    this.updateHelp(props.panelItem);

    this.focusedBefore = document.activeElement as HTMLElement;
  }

  updateHelp = (item: PFXMenuItem) => {
    this.helpText = null;
    if (item && item.to) {

      const path = item.to as string;
      const pathEntries = path.split('/');

      const page = pathEntries[pathEntries.length - 1];
      const entry =
        page && page !== '/'
          ? 'PFX::Help::' + item.scope + '::' + page
          : 'PFX::Help::' + item.scope;
      const content = this.props.terms[entry];
      /*
        ? this.props.terms[entry]
        : this.props.terms[PFXMessages.NODOCUMENTATION]
      */
      if (content && content.trim() !== '') {
        const parsed = ReactHtmlParser(content);
        if (parsed && parsed.length > 0) {
          this.helpText = parsed;
        }
      }
    }
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    let update = false;
    // Never Delete this lovely timesaver!
    // debug: compare props to figure out what caused change
    for (const key in nextProps) {
      if (nextProps[key] !== this.props[key]) {
        let thisUpdated = false;

        thisUpdated = true;

        if (thisUpdated) {
          console.debug('ActionPanel, Prop changed: ' + key);
          console.debug(' - old:', this.props[key]);
          console.debug(' - new:', nextProps[key]);
        }

        if (key === 'panelItem' && (thisUpdated || !this.helpText)) {
          if (nextProps.panelItem && nextProps.panelItem.to) {
            this.updateHelp(nextProps.panelItem);
          }
        }

        update = update || thisUpdated;
      }
    }
    return update;
  }

  doDismiss = (app: IAppEditState) => {
    // user wants to continue, clear dirty flag
    if (app && app.isUnSaved === true) {
      window.store.dispatch({
        type: types.UNSAVED_CHANGE,
        payload: {
          appEditState: {
            parentId: app.parentId,
            appId: app.appId,
            appName: app.appName,
            isUnSaved: false
          }
        }
      });
    }

    window.store.dispatch(closePanelAndRestoreAppFocus({
      restoreFocusToElementWithId: PFXState().restoreFocusToElement
    }));

    window.store.dispatch(clearAlert());

    /* Dilemma: Cannot clear all filters as this ruins main view, cannot leave filters from dialog as this ruins next dialog view */
    /* Solution: clear common filters, let panel initiator specify additional filters through "dismissFilters" */
    let clearFilters = [
      'PFX_ShowFormHistory',
      'PFX_ShowWebHistory',
      'PFX_ShowUserHistory',
      'EditRowId',
      'FormId',
      'ContextForm'
    ];

    if (this.props?.panelItem?.componentProps) {
      clearFilters = clearFilters.concat(this.props.panelItem.componentProps.dismissFilters);
    }

    window.ProjectFlowX.clearGlobalFilter(clearFilters);

    if (this.props && this.props.onClose) {
      this.props.onClose(true);
    }
  }

  close = (ev?: React.SyntheticEvent<HTMLElement>) => {
    const state = PFXState();
    const parentId = this.props.panelItem.component + '::' + this.props.panelItem.componentProps.composition;
    const unsavedApp = getUnsavedApp(state.appEditStates, parentId);

    if (ev && ev.target && PFXHelpers.disableLightDismiss(ev.target as HTMLElement)) {
      return;
    }

    new Promise<void>((resolve, reject) => {

      if (unsavedApp) {
        const terms = state.terms;
        const dlg: PFXDialog = {
          title: terms['PFX::Messages::UnsavedChanges'],
          description: terms['PFX::Messages::UnsavedDataContinue'],
          confirmBtnText: terms['PFX::Shared::Leave'],
          cancelBtnText: terms['PFX::Shared::Stay'],

          onConfirmClick: () => {
            window.store.dispatch({
              type: types.DIALOG_CHANGE,
              payload: { dialog: null },
            });
            resolve();
          },

          onCancelClick: () => {
            ev.preventDefault();
            window.store.dispatch({
              type: types.DIALOG_CHANGE,
              payload: { dialog: null },
            });
            reject();
          },
        };

        window.store.dispatch({
          type: types.DIALOG_CHANGE,
          payload: { dialog: dlg },
        });
      } else {
        resolve();
      }
    }).then(() => {
      const processingApp = getProcessingApp(state.appProcessingStates, parentId);

      if (processingApp === null || processingApp.isProcessing === false) {
        this.doDismiss(unsavedApp);
      } else {

        // some app is doing something, give it a chance to finish
        // after some tries dismiss panel anyway
        let tries = 0;
        const interval = setInterval(() => {
          const processingApp2 = getProcessingApp(PFXState().appProcessingStates, parentId);
          if (tries > 10 || processingApp2 === null || processingApp2.isProcessing === false) {
            clearInterval(interval);
            this.doDismiss(unsavedApp);
          }
          tries++;
          // tslint:disable-next-line:align
        }, 200);
      }
    });

    if (this.focusedBefore) {
      this.focusedBefore.focus();
    }
  };

  public render(): JSX.Element {
    const combinedProps = {
      ...this.props.panelItem.componentProps,
      onCancel: () => this.props.onClose(),
      onSave: () => this.props.onClose()
    };

    const content = PFXComponentFactory(
      this.props.panelItem.component,
      combinedProps,
      PFXRenderZone.ACTIONPANEL,
      this.props.panelItem
    );

    const isLightDismiss = this.props.panelItem.componentProps ? (!(this.props.panelItem.componentProps.disableLightDismiss === true)) : true;

    if (this.props.panelItem.panelWidth) {
      styling.updateCSSVar(styling.vars.pfxActionpanelWidth, this.props.panelItem.panelWidth);
    } else {
      styling.updateCSSVar(styling.vars.pfxActionpanelWidth, styling.defaults.pfxActionpanelWidth);
    }

    return (
      <Panel
        className={'pfx-sidepanel'}
        isOpen={this.props.panelItem && true}
        isBlocking
        type={this.props.panelItem.panelType ? this.props.panelItem.panelType : (this.props.panelItem.panelWidth ? PanelType.custom : PanelType.smallFixedFar)}
        onDismiss={this.close}
        headerText={this.helpText ? null : this.props.panelItem.title}
        onOuterClick={() => (isLightDismiss)}
        focusTrapZoneProps={{ forceFocusInsideTrap: false }}
        closeButtonAriaLabel={this.props.terms[PFXShared.CLOSE]}
        isLightDismiss={isLightDismiss}
        customWidth={'var(--pfx-actionpanel-width)'}
        styles={actionPanelStyles}
        allowTouchBodyScroll={true}
      >
        {this.props.panelItem.hasCustomContent && (
          <PivotItem headerText={this.props.terms[PFXShared.OTHERCONTENT]}>
            <CoreFlowAppBase
              contextWeb={this.props.contextWeb}
              contextUser={this.props.contextUser}
              lcid={this.props.lcid}
              appName={AppType.Composition}
              toolName={ToolType.Composition}
              toolId={'PFX_RenderContent'}
              onChange={() => console.log('change')}
              fullPageLayout={true}
              filter={{
                PFX_Filter_Placement: PFXContentPlacement.ACTIONPANEL_TOP,
                PFX_Filter_TargetId: this.props.panelItem.ID,
                PFX_Filter_Scope: this.props.panelItem.scope
              }}
              disableUnsavedCheck={true}
              layoutMode={this.props.layoutMode}
            />
          </PivotItem>
        )}
        {this.helpText
          ? <Pivot linkFormat={'links'} >
            <PivotItem headerText={this.props.panelItem.title} alwaysRender>
              {this.props.children}{content}
            </PivotItem>

            <PivotItem headerText={this.props.terms[PFXShared.HELP]}>
              <div
                /* className="card-pf card-pf-accented" */
                style={{
                  marginTop: '10px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  paddingLeft: '8px'
                }}
              >
                <h3>
                  <Icon
                    className={this.props.panelItem.iconClass}
                    style={{
                      fontSize: '1em',
                      float: 'left',
                      marginRight: '5px'
                    }}
                  />
                  {this.props.terms['PFX::System::' + this.props.panelItem.scope] +
                    ' > ' +
                    this.props.panelItem.title}
                </h3>
                {this.helpText}
                <br />
                <br />
              </div>
            </PivotItem>
          </Pivot>
          : <>{this.props.children}{content}</>
        }

        {this.props.panelItem.hasCustomContent && (
          <PivotItem headerText={this.props.terms[PFXShared.OTHERCONTENT]}>
            <CoreFlowAppBase
              contextWeb={this.props.contextWeb}
              contextUser={this.props.contextUser}
              lcid={this.props.lcid}
              appName={AppType.Composition}
              toolName={ToolType.Composition}
              toolId={'PFX_RenderContent'}
              fullPageLayout={true}
              filter={{
                PFX_Filter_Placement: PFXContentPlacement.ACTIONPANEL_BOTTOM,
                PFX_Filter_TargetId: this.props.panelItem.ID,
                PFX_Filter_Scope: this.props.panelItem.scope
              }}
              disableUnsavedCheck={true}
              layoutMode={this.props.layoutMode}
            />
          </PivotItem>
        )}

      </Panel>
    );
  }

  /*
  private _onRenderFooterContent = (): JSX.Element => {
    return (
      <div>
        <PrimaryButton
          onClick={this.props.onClose}
          style={{ marginRight: '8px' }}
        >
          Save
        </PrimaryButton>
        <DefaultButton onClick={this.props.onClose}>Cancel</DefaultButton>
      </div>
    );
  };
  */
}
