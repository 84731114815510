import { IconButton, Stack, StackItem, TextField } from '@fluentui/react';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { isEnterKeySequence } from './isEnterKeySequence';

type SendBoxProps = {
    onSendMessage: (content: string) => Promise<void>
    onNewTopic: () => Promise<void>
}

export function SendBox({ onSendMessage, onNewTopic }: SendBoxProps): React.ReactElement {
    const [inputValue, setInputValue] = useState("")

    const sendMessage = useCallback((content: string) => {
        setInputValue("")
        onSendMessage(content)
    }, [onSendMessage])

    const newTopic = useCallback(() => {
        setInputValue("")
        onNewTopic()
    }, [onNewTopic])

    const [multiline, setMultiline] = useState(false)
    return (
        <Stack horizontal>
            <IconButton title="Nyt emne" iconProps={{ iconName: "CommentAdd" }} onClick={() => newTopic()} />
            <StackItem grow>
                <TextField value={inputValue} onKeyDown={(event) => {
                    if (isEnterKeySequence(event)) {
                        if (inputValue.trim() === "") return
                        sendMessage(inputValue)
                    }
                }} onChange={(_, newValue) => {
                    newValue.length > 48 ? setMultiline(true) : setMultiline(false)
                    setInputValue(newValue)
                }} autoFocus placeholder='Stil et spørgsmål' autoAdjustHeight multiline={multiline} resizable={false} autoComplete="" />
            </StackItem>
            <IconButton iconProps={{ iconName: "Send" }} onClick={() => sendMessage(inputValue)} disabled={inputValue.trim() === ""} />
        </Stack>
    )
}