import jsonRequest from './jsonRequest';
import { COREFLOW_API, PFXAIAssistantSetup, PFXState } from '../Root';
import { PFXHelpers } from 'src/utils/PFXHelpers';
import { PFXWeb } from './webApi';
import { updateLocalization, updateMenus } from 'src/redux/actionCreators';

const QUERY_PFX_SYSTEM = '/PFX_System/data';

export interface PFXSystemInfo {
  CFVersion: string;
  CFRevision: string;
  CFProcessAge: string;
  CFMem: string;
  PFXVersion: string;
  UserImage: string;
  HideNavigator: boolean;
  AIAssistantSetup: PFXAIAssistantSetup;
  IncludeArchived: boolean;
  GlobalCSS?: string;
  GlobalJS?: string;
  LCID?: number;
}

const systemApi = {
  getInfo(contextWeb: PFXWeb): Promise<PFXSystemInfo | null> {
    return jsonRequest(
      COREFLOW_API + QUERY_PFX_SYSTEM,
      contextWeb,
      { mode: 'simple' },
      'GET',
      true /* system is determining language, better not instruct it! */
    )
      .then((data): PFXSystemInfo => {
        const state = PFXState();
        const lcid = parseInt(data[0].LCID, 10);

        if (lcid && state.lcid !== lcid) {
          window.store.dispatch(updateLocalization(lcid));
          window.store.dispatch(updateMenus(state.contextWeb));
        }

        return {
          ...data[0],
          CFProcessStartDate: PFXHelpers.ticksToDate(
            data[0].CFProcessStartDate
          ),
          LCID: lcid,
        };
      })
      .catch((error) => {
        console.error(
          'systemApi.getInfo ERROR',
          { RequestUrl: COREFLOW_API + QUERY_PFX_SYSTEM },
          error
        );
        return null;
      });
  },
};

export default systemApi;
