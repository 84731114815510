import * as React from 'react';
import { PFXWeb } from 'src/api/webApi';
import * as Layout from '../Misc/Layouts';
import {
  QueryStringModifier,
  AppType,
  ToolType,
  IFilter
} from '../CoreFlow/CoreFlow';
import { PFXForm, PFXFormTypes } from 'src/api/formsApi';
// import { Icon } from '@fluentui/react/lib/Icon';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { PFXUser } from 'src/models';

import { updateForm } from 'src/redux/actionCreators';

import {
  PFXLocalizedTerms,
  PFXShared,
  PFXSystem
} from 'src/api/localizationApi';
import CoreFlowAppBase from '../CoreFlow/CoreFlowAppBase';
import { PFXAppLayoutMode } from 'src/styling/layout';

interface Props {
  contextWeb: PFXWeb;
  contextForm: PFXForm;
  contextUser: PFXUser;
  lcid: number;
  terms: PFXLocalizedTerms;
  formTypes: PFXFormTypes;
  newFormType?: string;
  layoutMode?: PFXAppLayoutMode;
}

interface State {
  queryString?: QueryStringModifier;
  filterObject?: IFilter;
  NewFormType?: string;
}

export class FormsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    let qs = {};
    /* tslint:disable:no-string-literal */
    if (props.contextForm && props.contextForm.FormGuid) {
      qs['formid'] = props.contextForm.FormGuid;
      qs['flowit:formeditmode'] = 'true';
    }

    if (props.newFormType) {
      qs['context_Forms'] = props.newFormType;
      qs['flowit:formeditmode'] = 'true';
    }
    /* tslint:enable:no-string-literal */

    if (props.contextForm && props.contextForm.FormGuid) {
      this.state = {
        queryString: qs,
        filterObject: {
          ContextForm: props.contextForm.FormGuid,
        }
      };
    } else {
      this.state = {
        queryString: qs,
        filterObject: {
          NewFormType: props.newFormType
        }
      };
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.contextForm &&
      this.props.contextForm.FormGuid &&
      this.props.contextForm !== prevProps.contextForm
    ) {
      const qs = { formid: this.props.contextForm.FormGuid };
      qs['flowit:formeditmode'] = 'true';
      this.setState({
        queryString: qs,
        filterObject: { ContextForm: this.props.contextForm.FormGuid }
      });
    }
  }

  onChange() {
    window.store.dispatch(updateForm(this.props.contextForm));
  }

  render() {
    const {
      contextWeb,
      contextForm,
      contextUser,
      lcid,
      terms,
      newFormType
    } = this.props;

    document.body.classList.add('cards-pf');
    let title = '';
    let formType = '';

    if (newFormType) {
      title = terms[PFXShared.NEW] + ' ' + terms[PFXSystem.PFX_STATUSREPORT];
      formType = newFormType;
    } else if (contextForm) {
      formType = contextForm.FormType;
      title = contextForm.PFX_Title;
      // Some types have no title - make sure we show something useful
      title =
        title === 'undefined'
          ? terms[PFXSystem.PFX_SYSTEM + '::' + formType]
          : title;
    }

    return (
      <Layout.OneCard>
        {!(this.state && this.state.queryString) && <Spinner />}
        {(contextForm || newFormType) && (
          <>
            {this.state && this.state.queryString && (
              <CoreFlowAppBase
                appName={AppType.Composition}
                toolName={ToolType.Composition}
                lcid={lcid}
                toolId={'PFX_FormView'}
                filter={this.state.filterObject}
                contextWeb={contextWeb}
                contextUser={contextUser}
                queryStringModifier={this.state.queryString}
                onChange={this.onChange}
                fullPageLayout={true}
                layoutMode={this.props.layoutMode}
              />
            )}
          </>
        )}
      </Layout.OneCard>
    );
  }
}

export default FormsView;
