import { AppInterface } from "src/Root";

export const OpenChatActionType = "OpenChat"

export type OpenChatAction = {
    type: typeof OpenChatActionType;
    payload: {};
};

function createOpenChatAction(): OpenChatAction {
    return {
        type: OpenChatActionType,
        payload: {}
    };
}

export function openChat(dispatch: typeof window.store.dispatch) {
    dispatch(createOpenChatAction())
}

export function openChatReducer(state: AppInterface, action: OpenChatAction): AppInterface {
    return {
        ...state,
        chatVisible: true
    }
}