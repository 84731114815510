import * as React from 'react';
import { PFXFormAlertSummary } from 'src/api/formAlerts';

import { PFXHelpers } from 'src/utils/PFXHelpers';
import { Callout, DirectionalHint } from '@fluentui/react/lib/Callout';
import { PFXUser } from 'src/models';
import { PFXWeb } from 'src/api/webApi';
import CoreFlowAppBase from '../CoreFlow/CoreFlowAppBase';
import { AppType, ToolType, IFilter } from '../CoreFlow/CoreFlow';

import { IconButton } from '@fluentui/react/lib/Button';
import { PFXShared, PFXLocalizedTerms } from 'src/api/localizationApi';

import { Link } from '@fluentui/react/lib/Link';
import { PFXFormTypes } from 'src/api/formsApi';
import { PFXAppLayoutMode } from 'src/styling/layout';

interface Props {
  title: string;
  formAlert: PFXFormAlertSummary;
  formTypes: PFXFormTypes;
  contextWeb: PFXWeb;
  contextUser: PFXUser;
  lcid: number;
  terms: PFXLocalizedTerms;
  filter?: IFilter;
  layoutMode?: PFXAppLayoutMode;
}

interface State {
  callOutVisible: boolean;
  alertType: string;
}

class FormAlert extends React.PureComponent<Props, State> {
  private callOutTarget: any;
  private filterObject: IFilter;

  constructor(props: Props) {
    super(props);
    this.filterObject = { FormType: props.formAlert.FormType };

    this.showERRORCallOut = this.showERRORCallOut.bind(this);
    this.showWARNINGCallOut = this.showWARNINGCallOut.bind(this);
    this.showOKCallOut = this.showOKCallOut.bind(this);
    this.state = {
      callOutVisible: false,
      alertType: null
    };
  }

  showERRORCallOut = (event: any) => {
    this.callOutTarget = (event as Event).currentTarget;
    this.setState({ alertType: 'ERROR', callOutVisible: true });
  };
  showWARNINGCallOut = (event: any) => {
    this.callOutTarget = (event as Event).currentTarget;
    this.setState({ alertType: 'WARNING', callOutVisible: true });
  };
  showREMINDERCallOut = (event: any) => {
    this.callOutTarget = (event as Event).currentTarget;
    this.setState({ alertType: 'REMINDER', callOutVisible: true });
  };
  showOKCallOut = (event: any) => {
    this.callOutTarget = (event as Event).currentTarget;
    this.setState({ alertType: 'OK', callOutVisible: true });
  };

  dismissCallOut = () => this.setState({ alertType: null, callOutVisible: false });

  render() {
    const props = this.props;
    if (this.filterObject.FormType !== props.formAlert.FormType) {
      this.filterObject = { FormType: props.formAlert.FormType };
    }
    return props.formAlert ? (
      <div
        className="card-pf card-pf-accented card-pf-aggregate-status"
        style={{
          borderTopColor: PFXHelpers.colors[props.formAlert.ProgressColor]
        }}
      >
        <h2 className="card-pf-title">
          <span
            className={
              'pfx-fabricicon ' +
              props.formTypes[props.formAlert.FormType].iconClass
            }
            style={{
              color: PFXHelpers.colors[props.formAlert.ProgressColor]
            }}
          />
          <br />
          {props.title}
        </h2>
        <div className="card-pf-body">
          <div style={{ minHeight: '312px' }}>
            <CoreFlowAppBase
              contextWeb={props.contextWeb}
              contextUser={props.contextUser}
              lcid={props.lcid}
              appName={AppType.DataGrid}
              toolName={ToolType.DataGrid}
              toolId={'PFX_FormSummary'}
              filter={{ ...props.filter, ...this.filterObject }}
              fullPageLayout={true}
              disableUnsavedCheck={true}
              layoutMode={props.layoutMode}
            />
          </div>

          <p className="card-pf-aggregate-status-notifications" style={{ minHeight: '41px' }}>
            {/* disabled per request #3163 (props.formAlert.ERROR + props.formAlert.WARNING + props.formAlert.REMINDER) > 0 && */ (
              <>
                <span className="card-pf-aggregate-status-notification">
                  <Link
                    onClick={this.showERRORCallOut}
                    disabled={props.formAlert.ERROR === 0}
                  >
                    <span className="pficon pficon-error-circle-o" />
                    {props.formAlert.ERROR}
                  </Link>
                </span>
                <span className="card-pf-aggregate-status-notification">
                  <Link
                    onClick={this.showWARNINGCallOut}
                    disabled={props.formAlert.WARNING === 0}
                  >
                    <span className="pficon pficon-warning-triangle-o" />
                    {props.formAlert.WARNING}
                  </Link>
                </span>
                <span className="card-pf-aggregate-status-notification">
                  <Link
                    onClick={this.showREMINDERCallOut}
                    disabled={props.formAlert.REMINDER === 0}
                  >
                    <span className="pficon pficon-info" />
                    {props.formAlert.REMINDER}
                  </Link>
                </span>
                {false && (
                  <span className="card-pf-aggregate-status-notification">
                    <Link
                      onClick={this.showOKCallOut}
                      disabled={props.formAlert.OK === 0}
                    >
                      <span className="pficon pficon-ok" />
                      {props.formAlert.OK}
                    </Link>
                  </span>
                )}
              </>
            )}
          </p>


          {this.state.callOutVisible && (
            <Callout
              className="pfx-alert-callout"
              gapSpace={0}
              target={this.callOutTarget}
              calloutMaxWidth={400}
              calloutMaxHeight={500}
              directionalHint={
                ['PFX_Product', 'PFX_Milestone'].indexOf(
                  props.formAlert.FormType
                ) > -1
                  ? DirectionalHint.topRightEdge
                  : DirectionalHint.topLeftEdge
              }
              directionalHintFixed
              onDismiss={this.dismissCallOut}
              setInitialFocus
              hidden={!this.state.callOutVisible}
            >
              <IconButton
                iconProps={{ iconName: 'ChromeClose' }}
                title={props.terms[PFXShared.CLOSE]}
                ariaLabel={props.terms[PFXShared.CLOSE]}
                onClick={this.dismissCallOut}
                styles={{
                  root: {
                    position: 'absolute',
                    right: '0px',
                    zIndex: 1000
                  },
                  icon: {
                    fontSize: 'smaller'
                  }

                }}
              />
              <CoreFlowAppBase
                contextWeb={props.contextWeb}
                contextUser={props.contextUser}
                lcid={props.lcid}
                appName={AppType.DataGrid}
                toolName={ToolType.DataGrid}
                toolId={'PFX_FormAlerts'}
                filter={{
                  FormType: this.props.formAlert.FormType,
                  PFXWeb: this.props.contextWeb.guid,
                  AlertType: this.state.alertType
                }}
                fullPageLayout={true}
                disableUnsavedCheck={true}
                layoutMode={props.layoutMode}
              />
            </Callout>
          )}
        </div>
      </div>
    ) : null;
  }
}

export default FormAlert;
