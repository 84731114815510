import { FileTypeIconSize, ImageFileType, getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { FileIconTypeInput } from '@fluentui/react-file-type-icons/lib/FileIconType';
import { Icon } from '@fluentui/react/lib/Icon';

import { MessageBarType } from '@fluentui/react/lib/MessageBar';

import { RouterState } from 'connected-react-router';
import domtoimage from 'dom-to-image';
import { createBrowserHistory } from 'history';

import * as React from 'react';
import { renderToString } from 'react-dom/server';
import { CombinedState, Store } from 'redux';
import { PFXAssetTypes } from './api/O365Api';
import { PFXFormAlertSummary } from './api/formAlerts';
import { PFXForm, PFXFormTypes } from './api/formsApi';
import { PFXLocalizedTerms } from './api/localizationApi';
import { PFXNotificationPanel } from './api/notificationsApi';
import { PFXProductStatus } from './api/productStatusApi';
import { PFXSystemInfo } from './api/systemApi';
import { PFXWeb } from './api/webApi';
import { AzureAuthority, Msal2Auth } from './authentication/Msal2Auth';
import { AutomationMessage, IAppEditStates, IAppProcessingStates, IChannelSubscription, IFilter, cfx_web_ns, getProcessingApp, getUnsavedApp } from './components/CoreFlow/CoreFlow';
import {
  PFXCallOut,
  PFXCallOutSettings
} from './components/CoreFlow/CoreFlowCallOut';
import { PFXAlert } from './components/Misc/Alert';
import PhaseMap, { Phase } from './components/Misc/PhaseMap';
import { PFXDialog } from './components/Misc/SimpleDialog';
import { PFXUser } from './models';
import PFXMenuItem, { PFXComponentProps, PFXMenuItemType } from './models/PFXMenuItem';
import * as types from './redux/actionConstants';
import { clearAlert, closePanelAndRestoreAppFocus, loadPath, openPanelAndCaptureAppFocus, setAlert } from './redux/actionCreators';
import './utils/Extensions';
import { PFXHelpers } from './utils/PFXHelpers';

import { PFXAppLayoutMode, getInitialLayoutMode } from './styling/layout';
import * as styling from './styling/styling';

declare var cfx_web: typeof cfx_web_ns;

// Also fixes unused import warning
if (!React) {
  console.debug('React missing');
}

// #7108 count errors pre app init
export const initErrorCount = () => {
  window.ProjectFlowX.errorCounter = (window.ProjectFlowX.errorCounter ?? 0) + 1;
};
window.onerror = initErrorCount;

// #7108 ensure we monitor weak console.error reporting
const originalConsoleErrorFunctionPointer = console.error;
console.error = (...args: any) => {
  originalConsoleErrorFunctionPointer(...args);
  if (window.store) {
    window.store.dispatch({ type: types.APPEND_ERROR });
  } else {
    initErrorCount();
  }
};

export const devMode = window.location.hostname.toLowerCase().startsWith('localhost') || window.location.hostname.toLowerCase().startsWith(`${process.env.REACT_APP_DEV_PFX_HOSTNAME}`);

window.ProjectFlowX.consoleLog = console.log;
window.ProjectFlowX.consoleDebug = console.debug;
window.ProjectFlowX.consoleInfo = console.info;

const no_logging = () => null;

export const enableConsoleLogging = (): void => {
  if (console.log === no_logging) {
    console.log = window.ProjectFlowX.consoleLog ?? console.log;
    console.debug = window.ProjectFlowX.consoleDebug ?? console.debug;
    console.info = window.ProjectFlowX.consoleInfo ?? console.info;
  }
};

export const disableConsoleLogging = (): void => {
  console.log("%cLogging disabled. Use CoreFlow switch to enable again.", "color: orange"); // #10359
  console.log = no_logging;
  console.debug = no_logging;
  console.info = no_logging;
};

if (
  location.search.indexOf('flowitlog=') === -1 &&
  location.search.indexOf('consolelog=') === -1 &&
  !devMode &&
  window.location.hostname.indexOf('flowit.biz') === -1
) {
  // disable log traffic
  disableConsoleLogging();
}

export const baseName = '';

export enum PFXSlugType {
  ROOT = 0,
  PROJECT = 1,
  CATEGORY = 2,
  ITEM = 3,
  DRIVE = 4,
  DRIVEITEM = 5
}

const _getSlug = (
  Url: string,
  slugType: PFXSlugType = PFXSlugType.PROJECT,
  offset: number
): string =>
  (
    Url.split('?')[0].split('/')[
    baseName ? slugType + offset + 1 : slugType + offset
    ] || (slugType === PFXSlugType.PROJECT ? '/' : '')
  ).replace(
    new RegExp('(pages)|(settings)|(user)|(help)|(public)', 'ig'),
    'Portal'
  );

export const preparePath = (path: string): string => {
  // root cleanup and direct root slug support
  path = path === '//' ? '/' : path.startsWith('/') ? path : '/' + path;
  path = path === '/' || path === '' ? '/Portal' : path;
  return path;
};

export const getSlugFromUrl = (
  Url: string,
  slugType: PFXSlugType = PFXSlugType.PROJECT
): string => _getSlug(Url, slugType, 2);

export const getSlugFromPathname = (
  Url: string,
  slugType: PFXSlugType = PFXSlugType.PROJECT
): string => _getSlug(Url, slugType, 0);
export interface CoreFlow {
  CoreFlowFxUrl: string;
}

export interface ProjectFlowX {
  solutionName: string;
  azureAppId: string;
  tenantId: string;
  azureAuthority: AzureAuthority;
  coreflow: {
    resourceId: string;
    caasUrl: string;
    restApi: string;
    cFxPath: string;
  };
  navigate: (path: string, event?: MouseEvent) => void;
  setMessage: (message: string, linkText?: string, linkUrl?: string, type?: number, clearAfterClick?: boolean) => void;
  callOut: (target: any, composition: string) => void;
  inlinePanel: (title: string, composition: string) => void;
  dismissInlinePanel: () => void;
  dismissUnsavedState: () => void;
  actionPanel: (title: string, composition: string) => void;
  dismissActionPanel: (checkUnsavedState?: boolean) => Promise<boolean>;
  getFileIconClass: (extension: string, size: number, type: number, imageFileType: string) => string;
  getPNG: (nodeId: string, name?: string) => void;
  getSVG: (nodeId: string, name?: string) => void;
  getPhaseMap: (target: any, data: any) => void;
  initIcons: () => void;
  messageSubscription: IChannelSubscription;
  cookieTokenSetEventSubscription: cfx_web_ns.EventSubscription;
  filterChangeSubscription: cfx_web_ns.Unsubscribable;
  changeLogMode: cfx_web_ns.Unsubscribable;
  currentCookieTokenSetEventTimestamp: number | undefined;
  lastMessage: { message: AutomationMessage, stamp: number };
  fallbackRefresh: any;
  clearGlobalFilter: (filter: string[]) => void;
  errorCounter: number;
  consoleLog: () => void;
  consoleDebug: () => void;
  consoleInfo: () => void;
  updateCSSVar: (variable: string, value: string) => void;
}

declare global {
  interface Window {
    CoreFlow: CoreFlow;
    ProjectFlowX: ProjectFlowX;
    auth: Msal2Auth;
    // pfxAuthenticationContext: AuthenticationContext;
    user: PFXUser; // AuthenticationContext.UserInfo;
    store: Store<CombinedState<{ router: RouterState<{}>; appReducer: AppInterface; }>, any>;
  }
}

export const COREFLOW_API =
  window.ProjectFlowX.coreflow.caasUrl + window.ProjectFlowX.coreflow.restApi;


export const PFX_LOGO = require('./img/logo-alt.svg?url');
export const FLOWIT_LOGO = require('./img/pflogo.svg?url');
export const PFX_BRAND = require('./img/brand-alt.svg?url');
export const PFX_BRAND_TABLET = require('./img/brand-tablet.svg?url');

export const PFX_PAGE_IDS = {
  ALERTPANEL: '22c97ccb-b7e9-4ab6-a0b6-4b8dccee3ae7'
};

export const PublicLogin: PFXMenuItem = {
  title: 'Login',
  to: '/Public/Login',
  hidden: true,
  scope: 'Public',
  component: 'Login'
};

export const PublicLogout: PFXMenuItem = {
  title: 'Logout',
  to: '/Public/Logout',
  hidden: true,
  scope: 'Public',
  component: 'Logout'
};

export const PublicNotFound: PFXMenuItem = {
  title: 'Not Found',
  to: '/Public/NotFound',
  hidden: true,
  scope: 'Public',
  component: 'NotFound'
};

export const history = createBrowserHistory({
  basename: baseName
});

// Beware that type safety is ruined by setting "strictNullChecks": false in tsconfig. ElementId can be null but appears as only being of type string due to disabled strictNullChecks.
export type ElementId = string | null

export interface AppInterface {
  appInstance: string;
  location: Location;
  lcid: number;
  webs: PFXWeb[];
  forms: PFXForm[];
  formTypes: PFXFormTypes;
  assetTypes: PFXAssetTypes;
  terms: PFXLocalizedTerms;
  contextWeb: PFXWeb;
  contextForm: PFXForm;
  contextUser: PFXUser;
  contextIsLoading: boolean;
  isInitializing: boolean;
  isUnauthorized: boolean;
  isServerOffline: boolean;
  isUpdateRequired: boolean;
  isForcedReload: boolean;
  appStatus: string;
  menusAreLoading: boolean;
  menuItems: PFXMenuItem[];
  currentPath: PFXMenuItem[];
  layoutMode: PFXAppLayoutMode;
  mastheadHidden: boolean;
  menuCollapsed: boolean;
  topMenuHidden: boolean;
  helpVisible: boolean;
  chatVisible: boolean;
  alertsVisible: boolean;
  actionPanelItem: PFXMenuItem;
  restoreFocusToElement: ElementId;
  inlinePanelItem: PFXMenuItem;
  alert: PFXAlert;
  formAlerts: PFXFormAlertSummary[];
  productStatus: PFXProductStatus[];
  notificationPanels: PFXNotificationPanel[];
  dataTick: number;
  callOut: PFXCallOut;
  dialog: PFXDialog;
  forceReload?: boolean;
  systemInfo: PFXSystemInfo;
  appEditStates: IAppEditStates;
  appProcessingStates: IAppProcessingStates;
  filter: IFilter;
  errors: number;
  logMode: boolean;
}

export const PFXState = (): AppInterface => window.store.getState().appReducer;

export const appDebugEnabled = process.env.REACT_APP_DEBUG === 'true';
if (appDebugEnabled) {
  console.info('REACT_APP_DEBUG', appDebugEnabled);
}

export enum PFXStorage {
  MENU_COLLAPSED = 'pfx-menu-collapsed',
  HELP_VISIBLE = 'pfx-help-visible',
  HELP_PANEL_SIZES = 'pfx-help-panel-sizes',
  ALERTS_VISIBLE = 'pfx-alert-visible',
  FORCED_RELOAD = 'pfx-forced-reload',
  LOGIN_ATTEMPTS = 'pfx-login-attempts',
  LOGIN_INPROGRESS = 'pfx_login_inprogress',
  LOGIN_ACTIVEUSER = 'pfx_login_activeuser',
  FALLBACK_REFRESHED = 'pfx_fallback_refreshed'
}
export const PFXClearFlags = () => {
  localStorage.removeItem(PFXStorage.FORCED_RELOAD);
  localStorage.removeItem(PFXStorage.LOGIN_ATTEMPTS);
  sessionStorage.removeItem(PFXStorage.LOGIN_INPROGRESS);
};

export interface PFXAIAssistantSetup {
  Enabled: boolean;
  ApiUrl: string;
}

window.ProjectFlowX.updateCSSVar = styling.updateCSSVar;

export const initialState: AppInterface = {
  // auth: { login: null, logout: null, test: true },
  appInstance: Date.now().toString(),
  location: null,
  lcid: null,
  webs: [],
  forms: [],
  terms: {},
  formAlerts: [],
  formTypes: null,
  assetTypes: null,
  productStatus: [],
  contextWeb: null,
  contextForm: null,
  contextUser: null,
  contextIsLoading: false,

  isInitializing: true, // null,
  isUnauthorized: false,
  isServerOffline: false,
  isUpdateRequired: false,
  isForcedReload: false,

  appStatus: 'Loading...',
  menusAreLoading: false,
  menuItems: [],
  currentPath: [],
  layoutMode: getInitialLayoutMode(), // null,
  mastheadHidden: false,
  menuCollapsed:
    getInitialLayoutMode() === PFXAppLayoutMode.MOBILE
      ? true
      : (JSON.parse(
        localStorage.getItem(PFXStorage.MENU_COLLAPSED)
      ) as boolean),
  topMenuHidden: true,
  helpVisible: JSON.parse(
    localStorage.getItem(PFXStorage.HELP_VISIBLE)
  ) as boolean,
  chatVisible: false,
  alertsVisible: JSON.parse(
    localStorage.getItem(PFXStorage.ALERTS_VISIBLE)
  ) as boolean,
  actionPanelItem: null,
  restoreFocusToElement: null,
  inlinePanelItem: null,
  alert: null,
  dataTick: 0,
  notificationPanels: null,
  callOut: null,
  dialog: null,
  forceReload: false,
  systemInfo: null,
  appEditStates: null,
  appProcessingStates: null,
  filter: null,
  errors: null,
  logMode: false
};

document.addEventListener('click', (event: MouseEvent) => {
  const target = event.target as Element;
  if (target instanceof Element) {
    // ensure logic only applies to PFX related menu items and js links
    if (target.getAttribute('data-id') === 'pfx-menu'
      || target.parentElement?.getAttribute('data-id') === 'pfx-menu'
      || target.getAttribute('dataid') === 'pfx-menu'
      || target.parentElement?.getAttribute('dataid') === 'pfx-menu'
      || (target.getAttribute('onclick') ?? '').indexOf('ProjectFlowX.navigate') !== -1
    ) {
      if (event.ctrlKey || event.shiftKey || event.metaKey) {
        // Allows open in new tab/window navigation to take place (#4278)
        event.stopPropagation();
      } else {
        // Ensure JS links with href specified does not reload app by performing default navigation
        event.preventDefault();
      }
    }
  }
  // tslint:disable-next-line:align
}, true);

window.ProjectFlowX.navigate = (path: string, event?: MouseEvent, filter?: IFilter, newTab?: boolean) => {

  if (newTab === true) {
    window.open(path);
    return;
  }

  if (event) {
    event.preventDefault();

    if (event.ctrlKey || event.metaKey) {
      // new tab, #6742
      window.open(path);
      return;
    }

    if (event.shiftKey) {
      // new window, #6742
      window.open(path, '_blank');
      return;
    }

  }

  window.store.dispatch({
    type: types.CALLOUT_CHANGE,
    payload: { callOut: null }
  });

  window.store.dispatch(clearAlert());
  window.store.dispatch(loadPath(path, null, null, filter));
};

window.ProjectFlowX.setMessage = (message: string, linkText?: string, linkUrl?: string, type?: number, clearAfterClick?: boolean) => {
  window.store.dispatch(
    setAlert({
      type: (type ? type : 0) as MessageBarType,
      message,
      action: linkText ? { link: linkUrl, label: linkText, clearAfterClick } : null
    })
  );
};

window.ProjectFlowX.getFileIconClass = (
  extension: string,
  size: number = 20,
  type: number = 3,
  imageFileType: string = 'svg'
): string => {
  /*
  type:
    docset = 1,
    folder = 2,
    genericFile = 3,
    listItem = 4,
    sharedFolder = 5,
    multiple = 6

  size: 
    16 | 20 | 32 | 40 | 48 | 64 | 96

  imageFileType
    'svg' | 'png'
  */
  return renderToString(<Icon {...getFileTypeIconProps({
    extension: extension.toLowerCase(),
    type: (type as FileIconTypeInput),
    size: (size as FileTypeIconSize),
    imageFileType: (imageFileType as ImageFileType)
  })} className="pfx-file-icon" />);
};

window.ProjectFlowX.callOut = (
  target: any,
  composition: string,
  filter?: IFilter,
  settings?: PFXCallOutSettings
) => {
  window.store.dispatch({
    type: types.CALLOUT_CHANGE,
    payload: { callOut: { target, composition, filter, settings } }
  });
};

window.ProjectFlowX.inlinePanel = (
  title: string,
  composition: string,
  filter?: IFilter,
  width?: string,
  componentProps?: PFXComponentProps
) => {
  const item: PFXMenuItem = {
    title,
    itemType: PFXMenuItemType.inline,
    component: 'Composition',
    componentProps: {
      ...componentProps,
      composition,
      filter
    },
    panelWidth: width
  };

  window.store.dispatch({
    type: types.SET_INLINE_PANEL_ITEM,
    payload: { inlinePanelItem: item }
  });
};

window.ProjectFlowX.dismissInlinePanel = () => {
  window.store.dispatch({
    type: types.SET_INLINE_PANEL_ITEM,
    payload: { inlinePanelItem: null }
  });
  window.store.dispatch({
    type: types.UNSAVED_CLEAR
  });
};

window.ProjectFlowX.dismissUnsavedState = () => {
  window.store.dispatch({
    type: types.UNSAVED_CLEAR
  });
};

window.ProjectFlowX.actionPanel = (
  title: string,
  composition: string,
  filter?: IFilter,
  width?: string,
  componentProps?: PFXComponentProps,
  to?: string,
  scope?: string
) => {
  const item: PFXMenuItem = {
    title,
    itemType: PFXMenuItemType.inline,
    component: 'Composition',
    componentProps: {
      ...componentProps,
      composition,
      filter,
      fullPageLayout: true
    },
    panelWidth: width,
    to,
    scope
  };

  window.store.dispatch(openPanelAndCaptureAppFocus({
    actionPanelItem: item,
  }))

};

window.ProjectFlowX.dismissActionPanel = async (checkUnsavedState?: boolean): Promise<boolean> => {
  const state = PFXState();
  if (state && state.actionPanelItem && state.actionPanelItem.componentProps && state.actionPanelItem.componentProps.composition) {
    const parentId = state.actionPanelItem.component + '::' + state.actionPanelItem.componentProps.composition;
    const processingApp = getProcessingApp(state.appProcessingStates, parentId);
    const unsavedApp = getUnsavedApp(state.appEditStates, parentId);

    const closed = await new Promise<boolean>((resolve, reject) => {

      if (unsavedApp && checkUnsavedState === true) {
        const terms = state.terms;
        const dlg: PFXDialog = {
          title: terms['PFX::Messages::UnsavedChanges'],
          description: terms['PFX::Messages::UnsavedDataContinue'],
          confirmBtnText: terms['PFX::Shared::Leave'],
          cancelBtnText: terms['PFX::Shared::Stay'],
          onConfirmClick: () => {
            window.store.dispatch({
              type: types.DIALOG_CHANGE,
              payload: { dialog: null },
            });
            resolve(true);
          },
          onCancelClick: () => {
            window.store.dispatch({
              type: types.DIALOG_CHANGE,
              payload: { dialog: null },
            });
            reject(false);
          },
        };

        window.store.dispatch({
          type: types.DIALOG_CHANGE,
          payload: { dialog: dlg },
        });
      } else {
        resolve(true);
      }
    });

    if (closed) {
      if (processingApp === null || processingApp.isProcessing === false) {
        window.store.dispatch(closePanelAndRestoreAppFocus({
          restoreFocusToElementWithId: PFXState().restoreFocusToElement
        }))
      } else {

        // some app is doing something, give it a chance to finish
        // after some tries dismiss panel anyway
        await PFXHelpers.waitUntil((): boolean => {
          const processingApp2 = getProcessingApp(PFXState().appProcessingStates, parentId);
          return processingApp2 === null || processingApp2.isProcessing === false;
          // tslint:disable-next-line:align
        }, 200, 10);

        window.store.dispatch(closePanelAndRestoreAppFocus({
          restoreFocusToElementWithId: PFXState().restoreFocusToElement
        }));
      }

      // clear eventual panel unsaved state to avoid false unsaved dialog #5237
      // TODO: unsaved prompt for unsaved panel dismisses?

      if (unsavedApp && unsavedApp.isUnSaved === true) {
        window.store.dispatch({
          type: types.UNSAVED_CHANGE,
          payload: {
            appEditState: {
              parentId: unsavedApp.parentId,
              appId: unsavedApp.appId,
              appName: unsavedApp.appName,
              isUnSaved: false
            }
          }
        });
      }
    }

    return closed;

  } else {
    window.store.dispatch(closePanelAndRestoreAppFocus({
      restoreFocusToElementWithId: PFXState().restoreFocusToElement
    }))
    return true;
  }

};

window.ProjectFlowX.clearGlobalFilter = (filters: string[]) => {
  cfx_web.Public.clearFilterPropertiesSilently({ appId: 'PF365', initiator: 'window.ProjectFlowX.clearGlobalFilter' }, ...filters);
};

window.ProjectFlowX.initIcons = () => styling.initializeIconAssets();

window.ProjectFlowX.getPhaseMap = (target: any, data: any) => {
  data = data ?? {
    Layout: 'Slim', // Default | Slim
    Width: '80%',
    MinWidth: '220px',
    MaxWidth: '800px',
    Center: true, // Or, instead use text-align:left|center|right on #test
    LabelRotation: -45,
    OnMouseOver: function (stop: Phase) {
      console.log('Mouse over', stop);
    },
    OnMouseOut: function (stop: Phase) {
      console.log('Mouse out', stop);
    },
    OnClick: function (stop: Phase) {
      console.log('Clicked', stop);
    },
    Stops:
      [
        {
          Label: 'Opstart',
          Highlight: true, // Highlight this stop (circle) to indicate that it has been handled/completed
          SomeCustomProperty: 'https://projectflow.dk' // Add custom property needed in OnMouseOver/OnMouseOut/OnClick handlers
        },
        { Label: 'LOI', Highlight: true },
        { Label: 'Datarum', Highlight: true },
        { Label: 'Due diligence', Ripple: true }, // Ripple adds a subtle animation to the current task/step/process
        { Label: 'Q & A' },
        { Label: 'Signing' },
        { Label: 'Forberedelse' },
        { Label: 'Closing' },
        { Label: 'Post closing' }
      ]
  };

  return renderToString(<PhaseMap {...data} />);
};

window.ProjectFlowX.getPNG = (nodeId: string, name?: string) => {
  domtoimage.toPng(document.getElementById(nodeId))
    .then((dataUrl: string) => {
      const link = document.createElement('a');
      link.download = name ?? nodeId + '.png';
      link.href = dataUrl;
      link.click();
    });
};

window.ProjectFlowX.getSVG = (nodeId: string, name?: string) => {
  domtoimage.toPng(document.getElementById(nodeId))
    .then((dataUrl: string) => {
      const link = document.createElement('a');
      link.download = name ?? nodeId + '.svg';
      link.href = dataUrl;
      link.click();
    });
};
