import { MessageBarType } from '@fluentui/react';
import { push } from 'connected-react-router';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { enableServiceWorker } from 'src';
import { O365Api } from 'src/api/O365Api';
import { PFXFormAlertSummary, formAlertsApi } from 'src/api/formAlerts';
import { PFXForm, formsApi } from 'src/api/formsApi';
import { PFXMessages, localizationApi } from 'src/api/localizationApi';
import navigationApi from 'src/api/navigationApi';
import { PFXProductStatus, productStatusApi } from 'src/api/productStatusApi';
import systemApi from 'src/api/systemApi';
import {
  IFilter,
  cfx_web_ns,
  firstUnsavedApp,
} from 'src/components/CoreFlow/CoreFlow';
import onCookieTokenSetEvent from 'src/components/CoreFlow/onCookieTokenSetEvent';
import { PFXAlert } from 'src/components/Misc/Alert';
import { PFXDialog } from 'src/components/Misc/SimpleDialog';
import PFXMenuItem from 'src/models/PFXMenuItem';
import { PFXHelpers } from 'src/utils/PFXHelpers';
import { ElementId, PFXStorage, PublicNotFound, devMode } from '../Root';
import websApi, { PFXWeb } from '../api/webApi';
import * as types from './actionConstants';
import { RoutedAppState } from './actionReducers';
import { ClosePanelAction, OpenPanelAction } from './actionTypes';

declare var cfx_web: typeof cfx_web_ns;

export const setAlert =
  (alert: PFXAlert): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch) => {
    dispatch({
      type: types.SET_ALERT,
      payload: { alert: alert },
    });
  };

export const clearAlert =
  (): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch, getState) => {
    if (getState().appReducer.alert) {
      dispatch({
        type: types.SET_ALERT,
        payload: { alert: null },
      });
    }
  };

export const updateContextWeb =
  (web: PFXWeb): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch, getState) => {
    const state = getState().appReducer;
    const unsaved = firstUnsavedApp(state.appEditStates);
    if (!unsaved) {
      if (web) {
        // document title is handled in buildSideMenu
        dispatch({
          type: types.SET_CONTEXTWEB_DONE,
          payload: { contextWeb: web },
        });
      } else {
        document.title = window.ProjectFlowX.solutionName;
        dispatch({
          type: types.SET_CONTEXTWEB_DONE,
          payload: { contextWeb: null },
        });
        dispatch(push(PublicNotFound.to));
      }
    } else {
      dispatch(
        setAlert({
          type: MessageBarType.info,
          message: state.terms[PFXMessages.NEWCONTENT]
            ? state.terms[PFXMessages.NEWCONTENT]
            : 'New content is available. Please refresh!',
          action: {
            link: window.location.href,
            label: state.terms[PFXMessages.CLICKTORELOAD],
          },
        })
      );
      console.debug('unsaved app detected, context web reload skipped');
    }
  };

export const updateContextForm =
  (form: PFXForm): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch, getState) => {
    if (form) {
      dispatch({
        type: types.SET_CONTEXTFORM_DONE,
        payload: { contextForm: form },
      });
    } else {
      dispatch({
        type: types.SET_CONTEXTFORM_DONE,
        payload: { contextForm: null },
      });
    }
  };

export const updateFormAlerts =
  (webs: PFXWeb[]): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch, getState) => {
    if (webs && webs.length > 0) {
      if (webs[0]) {
        dispatch({ type: types.LOAD_FORM_ALERTS });

        const newFormAlerts: PFXFormAlertSummary[] =
          await formAlertsApi.fetchData(webs, getState().appReducer.contextWeb);

        dispatch({
          type: types.LOAD_FORM_ALERTS_DONE,
          payload: { formAlerts: newFormAlerts || null },
        });
      } else {
        console.debug('updateFormAlerts called with undefined entry web');
      }
    } else {
      console.debug('updateFormAlerts called with empty params');
    }
  };

export const updateMenus =
  (
    contextWeb: PFXWeb
  ): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch) => {
    dispatch({ type: types.LOAD_MENUS });

    navigationApi.fetchMenuItems(contextWeb).then((result) =>
      dispatch({
        type: types.LOAD_MENUS_DONE,
        payload: { menuItems: result },
      })
    );
  };

export const updateLocalization =
  (
    /* contextWeb: PFXWeb */
    lcid: number
  ): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch) => {
    dispatch({
      type: types.LOAD_LOCALIZATION,
      payload: { lcid },
    });

    localizationApi.fetchData().then((result) => {
      dispatch({
        type: types.LOAD_LOCALIZATION_DONE,
        payload: { terms: result, lcid },
      });
      PFXHelpers.setLanguage(lcid);
    });
  };

export const updateAssetTypes =
  (): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch) => {
    dispatch({ type: types.LOAD_ASSETTYPES });

    O365Api.fetchAssetTypes().then((result) =>
      dispatch({
        type: types.LOAD_ASSETTYPES_DONE,
        payload: { assetTypes: result },
      })
    );
  };

// --------------------------------------------------------------------------------

export const reloadContextWeb =
  (): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch, getState) => {
    dispatch({ type: types.SET_CONTEXTWEB });
    console.debug(getState());
    const slug = getState().appReducer.contextWeb.slug;

    let contextWeb = (await websApi.fetchWeb(slug, null)) as PFXWeb;

    if (contextWeb) {
      await Promise.all([dispatch(updateContextWeb(contextWeb))]);
    } else {
      console.error('ContextWeb was null, reloadContextWeb');
    }
  };

export const loadPath =
  (
    path: string,
    force?: boolean,
    keepAlert?: boolean,
    initFilter?: IFilter
  ): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch, getState) => {
    // tslint:disable-next-line:no-unused-expression
    new Promise<void>((resolve, reject) => {
      const unsavedApp = firstUnsavedApp(getState().appReducer.appEditStates);

      if (unsavedApp) {
        const terms = getState().appReducer.terms;
        const dlg: PFXDialog = {
          title: terms['PFX::Messages::UnsavedChanges'],
          description: terms['PFX::Messages::UnsavedDataContinue'],
          confirmBtnText: terms['PFX::Shared::Leave'],
          cancelBtnText: terms['PFX::Shared::Stay'],
          onConfirmClick: () => {
            dispatch({
              type: types.DIALOG_CHANGE,
              payload: { dialog: null },
            });
            resolve();
          },
          onCancelClick: () => {
            dispatch({
              type: types.DIALOG_CHANGE,
              payload: { dialog: null },
            });
            reject();
          },
        };

        dispatch({
          type: types.DIALOG_CHANGE,
          payload: { dialog: dlg },
        });
      } else {
        resolve();
      }
    })
      .then(() => {
        if (window.ProjectFlowX.cookieTokenSetEventSubscription) {
          window.ProjectFlowX.cookieTokenSetEventSubscription.unsubscribe();
        }

        cfx_web.Public.prepareNavigation({
          appId: 'PF365',
          initiator: 'loadPath',
        });

        window.ProjectFlowX.cookieTokenSetEventSubscription =
          cfx_web.Public.subscribeToCookieTokenSetEvents(onCookieTokenSetEvent);

        // root cleanup and direct root slug support
        path = path === '//' ? '/' : path.startsWith('/') ? path : '/' + path;
        path = path === '/' || path === '' ? '/Portal' : path;

        const filter = location.search || '';
        if (force === true) {
          dispatch({
            type: types.FORCE_RELOAD,
            payload: { forceReload: true },
          });
        }

        if (!keepAlert) {
          dispatch(clearAlert());
        }
        // clear eventual unsaved state pollution
        dispatch({ type: types.UNSAVED_CLEAR });

        // clear eventual processing state pollution, this could be subject to change
        // as we're running an SPA and processing may take place in an app that doesn't
        // change during path change
        dispatch({ type: types.PROCESSING_CLEAR });

        if (initFilter) {
          /* highly experimental, set initialization filters */
          window.store.dispatch({
            type: types.SET_FILTER,
            payload: { filter: initFilter },
          });
        } else {
          window.store.dispatch({
            type: types.SET_FILTER,
            payload: { filter: {} },
          });
        }

        dispatch(push(path + filter)); // if changed, also see onFilterChange.ts in relation to #7406
      })
      .catch(() => {
        console.debug('Navigation cancelled by user');
      });
  };

export const logIn =
  (
    selectAccount: boolean = false
  ): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async () => {
    window.auth.clearCache();

    localStorage.setItem(
      PFXStorage.LOGIN_ATTEMPTS,
      JSON.stringify(
        parseInt(localStorage.getItem(PFXStorage.LOGIN_ATTEMPTS), 10) + 1
      )
    );

    if (
      parseInt(localStorage.getItem(PFXStorage.LOGIN_ATTEMPTS), 10) < 3 ||
      selectAccount
    ) {
      window.auth.loginRedirect(selectAccount);
    } else {
      const msg =
        'Too many login attempts' +
        ' (' +
        localStorage.getItem(PFXStorage.LOGIN_ATTEMPTS) +
        ')!';
      console.error(msg);
      window.store.dispatch(setUnauthorized(msg));
    }
  };

export const logOut =
  (): // auth: PFXAuthFunctions
  ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async () => {
    // Clear everything
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });

    // Perform Azure logout
    window.auth.clearCache();
    window.auth.logOut();
  };

export const updateWebs =
  (): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch, getState) => {
    dispatch({ type: types.LOAD_WEBS });

    const contextWeb = getState().appReducer.contextWeb;

    const webs = await websApi.fetchWebs(contextWeb);

    dispatch({
      type: types.LOAD_WEBS_DONE,
      payload: { webs: webs },
    });

    // Do we need to update contextWeb state?
    if (contextWeb && webs) {
      const web = webs.find((webItem) => webItem.guid === contextWeb.guid);
      if (web) {
        dispatch(updateContextWeb(web));
      }
    }
  };

export const updateWeb =
  (
    slugOrGuid: string,
    navigateTo?: boolean
  ): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch, getState) => {
    dispatch({ type: types.LOAD_WEB });

    const contextWeb = getState().appReducer.contextWeb;

    let web = (await websApi.fetchWeb(slugOrGuid, contextWeb)) as PFXWeb;

    let tries = 0;
    while (tries < 6 && !web) {
      await PFXHelpers.delay(250);
      web = (await websApi.fetchWeb(slugOrGuid, contextWeb)) as PFXWeb;
      tries++;
    }

    if (!web) {
      if (slugOrGuid === contextWeb.guid || slugOrGuid === contextWeb.slug) {
        console.warn(
          'Could not load/update current web "' +
            slugOrGuid +
            '". This is likely due to a change in permissions - restarting application...'
        );
        location.reload();
      }

      dispatch({
        type: types.LOAD_WEB_DONE,
        payload: { web: null },
      });
    } else {
      dispatch({
        type: types.LOAD_WEB_DONE,
        payload: { web: web },
      });

      // Do we need to update contextWeb state?
      if (contextWeb && web && contextWeb.guid === web.guid) {
        dispatch(updateContextWeb(web));
      }

      if (navigateTo === true) {
        window.ProjectFlowX.navigate('/' + web.slug);
      }
    }
  };

export const updateProductStatus =
  (web: PFXWeb): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch) => {
    dispatch({ type: types.LOAD_PRODUCT_STATUS });

    const newProductStatus: PFXProductStatus[] =
      await productStatusApi.fetchData(web);

    dispatch({
      type: types.LOAD_PRODUCT_STATUS_DONE,
      payload: { productStatus: newProductStatus || null },
    });
  };

export const updateForm =
  (form: PFXForm): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch) => {
    dispatch({ type: types.LOAD_FORM });

    const newForms: PFXForm[] = await formsApi.fetchFormData(
      [{ guid: form.WebGuid }],
      form.slug
    );

    dispatch({
      type: types.LOAD_FORM_DONE,
      payload: { form: newForms ? newForms[0] : null },
    });
  };

export const setUnauthorized =
  (status?: string): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch) => {
    dispatch({
      type: types.SET_UNAUTHORIZED,
      payload: { appStatus: status },
    });
  };

export const updatePage =
  (status?: string): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch) => {
    dispatch({
      type: types.UPDATE_PAGE,
      payload: { appStatus: status },
    });
  };

export const checkForUpdates =
  (force?: boolean): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch, getState) => {
    if (devMode) {
      // high frequency sw check if clientside code was updated
      const swReg = await navigator.serviceWorker.getRegistration();
      swReg?.update();
    }

    const state = getState().appReducer;
    if (force || !state.isUnauthorized) {
      const systemInfo = await systemApi.getInfo(state.contextWeb);
      if (systemInfo) {
        if (
          state.contextUser &&
          systemInfo.UserImage !== state.contextUser.profileImage
        ) {
          // Profile image changed, update it
          dispatch({
            type: types.SET_CONTEXTUSER,
            payload: {
              contextUser: {
                ...state.contextUser,
                profileImage: systemInfo.UserImage,
              },
            },
          });
        }

        if (
          !state.isServerOffline &&
          state.systemInfo &&
          !(
            systemInfo.PFXVersion === state.systemInfo.PFXVersion &&
            systemInfo.CFVersion === state.systemInfo.CFVersion
          )
        ) {
          // Update found
          const updMsg = 'An update is ready. Please refresh!';
          dispatch({
            type: types.SET_ALERT,
            payload: {
              alert: {
                type: MessageBarType.severeWarning,
                message: state.terms
                  ? state.terms[PFXMessages.NEWVERSIONSERVER]
                    ? state.terms[PFXMessages.NEWVERSIONSERVER].format(
                        window.ProjectFlowX.solutionName
                      )
                    : updMsg
                  : updMsg,
                action: {
                  link: window.location.href,
                  label: state.terms
                    ? state.terms[PFXMessages.CLICKTORELOAD]
                    : 'Reload',
                },
              },
            },
          });

          if (enableServiceWorker) {
            // force sw to check for clientside updates
            const swReg = await navigator.serviceWorker.getRegistration();
            swReg?.update();
          } else {
            const swReg = await navigator.serviceWorker.getRegistration();
            swReg?.unregister;
          }
        } else {
          // All OK, check if we need to clear previous offline screen...
          if (
            force ||
            (state.alert &&
              (state.isServerOffline ||
                state.isUpdateRequired ||
                !state.systemInfo))
          ) {
            if (state.isServerOffline !== false && !state.systemInfo) {
              // Offline on initial load, we need to refresh
              localStorage.setItem(
                PFXStorage.FORCED_RELOAD,
                JSON.stringify(true)
              );
              console.warn('Offline on initial load, we need to refresh');
              location.reload();
            } else {
              // Went offline during use, just remove alert
              dispatch(clearAlert());
              dispatch({
                type: types.SET_SERVER_STATE,
                payload: {
                  isServerOffline: false,
                  isUpdateRequired: false,
                  systemInfo,
                },
              });
            }
          }
        }
      } else {
        // server offline
        dispatch({
          type: types.SET_SERVER_STATE,
          payload: {
            isServerOffline: true,
            systemInfo: state.systemInfo,
          },
        });
        const offMsg = 'Could not connect to server, please wait!';
        dispatch({
          type: types.SET_ALERT,
          payload: {
            alert: {
              type: MessageBarType.blocked,
              message: state.terms
                ? state.terms[PFXMessages.SERVEROFFLINE]
                  ? state.terms[PFXMessages.SERVEROFFLINE].format(
                      window.ProjectFlowX.solutionName
                    )
                  : offMsg
                : offMsg,
            },
          },
        });
      }
    }
  };

export const clearUserImage =
  (): ThunkAction<Promise<void>, RoutedAppState, {}, Action> =>
  async (dispatch, getState) => {
    const state = getState().appReducer;
    dispatch({
      type: types.SET_CONTEXTUSER,
      payload: {
        contextUser: {
          ...state.contextUser,
          profileImage: null,
        },
      },
    });
  };

type OpenPanelOptions = {
  actionPanelItem: PFXMenuItem;
  // Beware that type safety is ruined by setting "strictNullChecks": false in tsconfig. ElementId can be null but appears as only being of type string due to disabled strictNullChecks.
  restoreFocusToElementWithId: ElementId;
};

function openPanel({
  actionPanelItem,
  restoreFocusToElementWithId,
}: OpenPanelOptions): OpenPanelAction {
  return {
    type: types.OpenPanelActionType,
    payload: {
      actionPanelItem,
      // Beware that type safety is ruined by setting "strictNullChecks": false in tsconfig. ElementId can be null but appears as only being of type string due to disabled strictNullChecks.
      restoreFocusToElementWithId,
    },
  };
}

function captureFocusedAppId() {
  const appSelector = ":is([id^='cfx-app-']";
  const containingAppId = document.activeElement.closest(appSelector)?.id;
  if (containingAppId === undefined) return null;
  if (containingAppId === '') return null;
  return containingAppId;
}

type OpenPanelAndCaptureAppFocusOptions = {
  actionPanelItem: PFXMenuItem;
};

export function openPanelAndCaptureAppFocus({
  actionPanelItem,
}: OpenPanelAndCaptureAppFocusOptions): OpenPanelAction {
  const focusedAppId = captureFocusedAppId();
  return openPanel({
    actionPanelItem,
    restoreFocusToElementWithId: focusedAppId,
  });
}

function closePanel(): ClosePanelAction {
  return {
    type: types.ClosePanelActionType,
  };
}

type ClosePanelAndRestoreAppFocusOptions = {
  // Beware that type safety is ruined by setting "strictNullChecks": false in tsconfig. ElementId can be null but appears as only being of type string due to disabled strictNullChecks.
  restoreFocusToElementWithId: ElementId;
};

export function closePanelAndRestoreAppFocus({
  restoreFocusToElementWithId,
}: ClosePanelAndRestoreAppFocusOptions): ClosePanelAction {
  // Beware that type safety is ruined by setting "strictNullChecks": false in tsconfig. ElementId can be null but appears as only being of type string due to disabled strictNullChecks.
  if (restoreFocusToElementWithId === null) return closePanel();
  if (restoreFocusToElementWithId === '') return closePanel();

  const restoreFocusToElement = document.getElementById(
    restoreFocusToElementWithId
  );
  if (restoreFocusToElement === undefined) return closePanel();

  restoreFocusToElement.focus();
  return closePanel();
}
