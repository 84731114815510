import * as React from 'react';
import { Grid } from 'patternfly-react/dist/js/components/Grid';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { PFX_LOGO, PFX_BRAND, PFXStorage } from '../../Root';
import { logIn, checkForUpdates } from 'src/redux/actionCreators';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Link } from '@fluentui/react';
import { PFXAppLayoutMode } from 'src/styling/layout';

interface Props {
  isInitializing: boolean;
  isUnauthorized: boolean;
  isServerOffline: boolean;
  message: string;
  layoutMode?: PFXAppLayoutMode;
}

interface State {
  lastMessage: string;
  messages: string[];
}

export default class Loader extends React.Component<Props, State> {

  private checkServerInterval: NodeJS.Timeout;

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.lastMessage !== props.message) {
      console.debug('[INIT] - ', props.message);
      return {
        value: props.message,
        messages: [props.message].concat(state.messages)
      };
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = { lastMessage: props.message, messages: [props.message] };
  }

  tryReloadOnce = () => {
    const hasReloaded = JSON.parse(localStorage.getItem(PFXStorage.FORCED_RELOAD)) as boolean;

    if (!hasReloaded) {
      localStorage.setItem(
        PFXStorage.FORCED_RELOAD,
        JSON.stringify(true)
      );
      console.warn('Forced Reload - Try Reloading Once');
      location.reload();
    }
  }

  componentDidMount() {
    // for this I will burn in hell...
    this.componentDidUpdate(this.props);
  }

  componentDidUpdate(props: Props) {
    if (props.isServerOffline) {
      this.tryReloadOnce();
      if (!this.checkServerInterval) {
        this.checkServerInterval = setInterval(
          () => window.store.dispatch(checkForUpdates(true)),
          2000
        );
        console.debug('Check Server Service Started');
      }
    }

    if (props.isUnauthorized) {
      setTimeout(() => {
        console.warn('Is Unauthorized - Forced Reload');
        location.reload();
        // tslint:disable-next-line:align
      }, 5000);
    }
  }

  render() {
    return (
      <Grid fluid className="login">
        <Grid.Row>
          <Grid.Col xs={12} className="login-upper-section">
            <div className="login-upper-section-align-bottom">
              <img
                src={PFX_LOGO}
                className="navbar-brand-icon"
                style={{ zoom: '150%', marginBottom: '10px' }}
              />
              <br />
              <img src={PFX_BRAND} className="navbar-brand-name" />
            </div>
          </Grid.Col>
          <Grid.Col xs={12} className="login-lower-section">
            {this.props.isInitializing && !this.props.isUnauthorized && (
              <Spinner
                size={SpinnerSize.large}
                label="Initializing..."
                ariaLive="assertive"
              />
            )}
            {/* this.state.messages.map((s, index) => (
              <div key={index}>{s}</div>
              'Could not connect to server! Please try again in a few minutes. If problem persists contact FlowIT.'
            )) */}

            {this.props.isServerOffline && (
              <>
                <p style={{ color: 'red' }}>Could not connect to server!</p>
                <p>
                  This may be due to maintenance or network issues.
                  <br />
                  Please <Link onClick={() => window.location.reload()}>refresh</Link> (press F5). If problem persists contact FlowIT.

                </p>
                <Spinner
                  size={SpinnerSize.large}
                  label="Waiting for server..."
                  ariaLive="assertive"
                />
              </>
            )}

            {this.props.isUnauthorized && (
              <>
                <p>Unauthorized Access!</p>
                <p style={{ color: 'red' }}>{this.props.message}</p>
                <p>(See Console Log details)</p>
                <PrimaryButton onClick={() => {
                  // We may be here due to multiple signed in accounts
                  // Allow user to switch account                  
                  window.store.dispatch(logIn(true));
                }}>
                  Start Login
                </PrimaryButton>
              </>
            )}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    );
  }
}
