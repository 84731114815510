import * as React from 'react';
import { PFXWeb } from 'src/api/webApi';
import { PFXUser } from 'src/models';
import * as Layout from '../Misc/Layouts';
import * as actionTypes from '../../redux/actionConstants';

import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import {
  QueryStringModifier,
  AppType,
  ToolType,
  cfx_web_ns,
  AquireToken,
  IFilter,
  AppEventType,
  IAppEventArgs,
  IAppEditStates,
  DataFormEventData,
  /*
  DataGridBuiltinSavedEventData,
  DataGridCustomSavedEventData
  */
} from './CoreFlow';
import { PFXHelpers } from 'src/utils/PFXHelpers';
import { loadPath } from 'src/redux/actionCreators';
import { PFXForm } from 'src/api/formsApi';
import eventApi from 'src/api/eventApi';
import { appDebugEnabled, PFXState } from 'src/Root';
import { PFXAppLayoutMode } from 'src/styling/layout';

declare var cfx_web: typeof cfx_web_ns;

interface Props {
  appName: AppType;
  toolName: ToolType;
  toolId: string;
  contextWeb: PFXWeb;
  lcid: number;
  contextUser: PFXUser;
  appEditStates?: IAppEditStates;
  contextForm?: PFXForm;
  contextWebIdOverride?: string; // allows for e.g. a metadata to be loaded from another web
  showSpinner?: boolean;
  queryStringModifier?: QueryStringModifier;
  dataTick?: number;
  pageId?: string;
  pageType?: string;
  pageIdentification?: string;
  fullPageLayout?: boolean;
  webAttr?: string;
  filter?: IFilter;
  onChange?: () => void;
  reloadOnChange?: boolean; // reload app
  updateOnChange?: boolean; // force component update  
  disableUnsavedCheck?: boolean;
  layoutMode?: PFXAppLayoutMode;
  wantsLayoutChange?: boolean;
}

export class CoreFlowAppBase extends React.Component<Props> {
  // private isDirty: boolean = false;
  private appInstance: string = PFXState().appInstance;

  shouldComponentUpdate(nextProps: Props): boolean {
    let update = false;
    // Never Delete this lovely timesaver!
    // debug: compare props to figure out what caused change
    for (const key in nextProps) {
      if (nextProps[key] !== this.props[key]) {
        let thisUpdated = false;

        if (key === 'contextWeb') {
          thisUpdated = !PFXHelpers.shallowCompare(this.props.contextWeb, nextProps.contextWeb);
        } else if (key === 'contextUser') {
          thisUpdated =
            this.props.contextUser.userName !==
            nextProps.contextUser.userName;
        } else if (key === 'contextForm') {
          thisUpdated =
            (this.props.contextForm && nextProps.contextForm) ? (this.props.contextForm.FormGuid !==
              nextProps.contextForm.FormGuid) : (nextProps.contextForm && true);
        } else if (key === 'filter') {
          thisUpdated = !PFXHelpers.shallowCompare(this.props.filter, nextProps.filter);
        } else if (key === 'queryStringModifier') {
          thisUpdated = !PFXHelpers.shallowCompare(this.props.queryStringModifier, nextProps.queryStringModifier);
        } else if (key === 'dataTick' && nextProps.appEditStates) {
          const firstUnsavedChild = Object.keys(nextProps.appEditStates).filter(
            app => nextProps.appEditStates[app].parentId === this.getKey() && nextProps.appEditStates[app].isUnSaved
          );
          // Only update if not unsaved (i.e. "dirty")
          thisUpdated = !firstUnsavedChild || firstUnsavedChild.length === 0;
        } else if (key === 'appEditStates') {
          // skip UI updates on internal unsaved prop changes..
          thisUpdated = false;
        } else if (key === 'layoutMode') {
          // only reload if page wantsLayoutChange ....
          if (this.props.wantsLayoutChange) {
            thisUpdated = true;
            console.debug('layoutMode change');
          } else {
            thisUpdated = false;
          }
        } else {
          thisUpdated = true;
        }

        update = update || thisUpdated;

        if (appDebugEnabled) {
          if (update) {
            console.debug('CoreFlow App Base, Prop changed: ' + key);
            console.debug(' - old:', this.props[key]);
            console.debug(' - new:', nextProps[key]);
          }
        }
      }
    }
    return update;
  }

  getKey = (): string => {
    if (this.props.appName && this.props.toolName && this.props.toolId) {
      return this.props.appName + '::' + this.props.toolId;
    } else {
      return '';
    }
  }

  onChange = (e?: IAppEventArgs) => {
    if (e) {
      if (appDebugEnabled) {
        console.debug('CoreFlow App Base, onChange', e, this.state);
      }

      if (e.type === AppEventType.ProcessingStarted) {
        window.store.dispatch({
          type: actionTypes.PROCESSING_CHANGE,
          payload: {
            appProcessingState: {
              parentId: this.getKey(),
              appId: e.appId,
              appName: e.appName,
              isProcessing: true
            }
          }
        });
      }

      if (e.type === AppEventType.ProcessingFinished) {
        const newState = {
          parentId: this.getKey(),
          appId: e.appId,
          appName: e.appName,
          isProcessing: false
        };
        window.store.dispatch({
          type: actionTypes.PROCESSING_CHANGE,
          payload: {
            appProcessingState: newState
          }
        });
      }

      if (e.type === AppEventType.Unsaved && (this.props.disableUnsavedCheck ?? false) !== true) {

        console.debug('unsaved');
        // this.isDirty = true;
        window.store.dispatch({
          type: actionTypes.UNSAVED_CHANGE,
          payload: {
            appEditState: {
              parentId: this.getKey(),
              appId: e.appId,
              appName: e.appName,
              isUnSaved: e.isUnSaved
            }
          }
        });

      }

      if (e.type === AppEventType.Saved) {

        if ((this.props.disableUnsavedCheck ?? false) !== true) {
          // this.isDirty = false;
          window.store.dispatch({
            type: actionTypes.UNSAVED_CHANGE,
            payload: {
              appEditState: {
                parentId: this.getKey(),
                appId: e.appId,
                appName: e.appName,
                isUnSaved: false
              }
            }
          });
        }


        const fire = () => {
          if (e.appName === 'DataForm') {
            // tslint:disable-next-line:no-string-literal
            // const changeType = (this.props.filter && this.props.filter['IsCreateDialog']) ? (this.props.filter['IsCreateDialog'].toLowerCase() === 'true' ? DataFormChangeType.Created : null) : null;
            const changeType = e.data ? (e.data as DataFormEventData).changeType : null;

            // tslint:disable-next-line:no-string-literal
            const showMessageBar = (this.props.filter && this.props.filter['showMessageBar']) ? (this.props.filter['showMessageBar'].toLowerCase() === 'true' ? true : false) : false;
            eventApi.onSave(this.props.contextWeb, e.appId, e.appName, e.toolId, e.data ? (e.data as DataFormEventData).editRowId : null, changeType, showMessageBar, this.props.toolId);
          } else if (e.appName === 'DataGrid') {
            console.debug('datagrid save event', e);
            eventApi.onSave(this.props.contextWeb, e.appId, e.appName, e.toolId, null, null, null, this.props.toolId);
          } else {
            eventApi.onSave(this.props.contextWeb, e.appId, e.appName, e.toolId, null, null, null, this.props.toolId);
          }

          if (this.props.updateOnChange === true) {
            this.forceUpdate();
          }

          if (this.props.onChange) {
            this.props.onChange();
          }

          if (this.props.reloadOnChange === true) {
            loadPath(window.location.pathname, true);
          }
        };
        const state = PFXState();
        const processingApp = state.appProcessingStates[e.appId];
        if (processingApp === null || processingApp.isProcessing === false) {
          fire();
        } else {
          // app is doing something, give it a chance to finish
          // after some tries fire update events anyway
          let tries = 0;
          const interval = setInterval(() => {
            const processingApp2 = state.appProcessingStates[e.appId];
            if (tries > 10 || processingApp2 === null || processingApp2.isProcessing === false) {
              clearInterval(interval);
              fire();
            }
            tries++;
            // tslint:disable-next-line:align
          }, 200);
        }

      }
    }
  };

  render() {
    if (appDebugEnabled) {
      console.debug('CoreFlow App Base, render', this.props);
    }
    const props = this.props;

    const composition = (
      <>
        {true && (
          <cfx_web.WebApp
            key={this.getKey() + this.props.dataTick ? this.props.dataTick : ''}
            appId={(props.pageId ?? '') + '/' + props.toolName + '/' + props.toolId /*PFXHelpers.objectHash(this.props).toString()*/}
            appName={props.appName}
            toolName={props.toolName}
            toolId={props.toolId}
            webId={
              props.contextWebIdOverride
                ? props.contextWebIdOverride
                : props.contextWeb.guid
            }
            appCustomizer={
              props.showSpinner
                ? {
                  splashScreenRenderer: () => (
                    <div style={{ padding: '10px' }}>
                      <Spinner size={SpinnerSize.large} />
                    </div>
                  )
                }
                : null
            }
            lcid={props.lcid}
            caasUrl={window.ProjectFlowX.coreflow.caasUrl}
            queryStringModifier={props.queryStringModifier}
            userName={props.contextUser.userName}
            pageType={props.pageType}
            pageIdentification={props.pageIdentification}
            webAttr={props.webAttr}
            filter={{
              ...props.filter,
              ...props.contextForm && { ContextForm: props.contextForm.FormGuid },
              ContextWeb_LastModified: props.contextWeb.lastModified.toString(),
              appInstance: this.appInstance,
              layoutMode: props.layoutMode
            }}
            acquireToken={AquireToken}
            /* onChange={this.onChange} */
            webUrl={props.contextWeb.url}
            event={this.onChange}
          />
        )}
      </>
    );
    if (props.fullPageLayout) {
      return composition;
    } else {
      return <Layout.OneCard>{composition}</Layout.OneCard>;
    }
  }
}

export default CoreFlowAppBase;
