import * as styling from './styling';
import * as PatternFly from 'patternfly-react/dist/js/common/patternfly';

export enum PFXRenderZone {
  HELPPANEL = 'HELPPANEL',
  TOPPANEL = 'TOPPANEL',
  PAGE = 'PAGE',
  MESSAGEBAR = 'MESSAGEBAR',
  ACTIONPANEL = 'ACTIONPANEL',
  NONE = 'NONE',
}

export enum PFXAppLayoutMode {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export enum PFXSideMenuMode {
  HIDDEN = 'none',
  BURGER = 'burger',
  EXPANDED = 'expanded',
  COLLAPSED = 'collapsed',
}

export enum PFXAlertPanelMode {
  EXPANDED = 'expanded',
  COLLAPSED = 'collapsed',
}

export const PFXLayoutConstants = {
  sideMenuWidths: {
    [PFXSideMenuMode.HIDDEN]: '3px',
    [PFXAppLayoutMode.DESKTOP]: {
      [PFXSideMenuMode.COLLAPSED]: '75px',
      [PFXSideMenuMode.EXPANDED]: styling.defaults.pfxSidemenuWidth,
    },
    [PFXAppLayoutMode.TABLET]: {
      [PFXSideMenuMode.COLLAPSED]: '75px',
      [PFXSideMenuMode.EXPANDED]: styling.defaults.pfxSidemenuWidth,
    },
    [PFXAppLayoutMode.MOBILE]: {
      [PFXSideMenuMode.COLLAPSED]: '0px',
      [PFXSideMenuMode.EXPANDED]: '100%',
    },
  },
  topMenuHeights: {
    [PFXAppLayoutMode.DESKTOP]: styling.defaults.pfxTopmenuHeight,
    [PFXAppLayoutMode.TABLET]: styling.defaults.pfxTopmenuHeight,
    [PFXAppLayoutMode.MOBILE]: '120px',
  },

  contentPadding: {
    [PFXAppLayoutMode.DESKTOP]: styling.defaults.pfxContentPadding,
    [PFXAppLayoutMode.TABLET]: styling.defaults.pfxContentPadding,
    [PFXAppLayoutMode.MOBILE]: '0px',
  },

  activePadding: {
    [PFXAppLayoutMode.DESKTOP]: '3px',
    [PFXAppLayoutMode.TABLET]: '3px',
    [PFXAppLayoutMode.MOBILE]: '0px',
  },

  cardPadding: {
    [PFXAppLayoutMode.DESKTOP]: styling.defaults.pfxCardPadding,
    [PFXAppLayoutMode.TABLET]: styling.defaults.pfxCardPadding,
    [PFXAppLayoutMode.MOBILE]: '3px',
  },

  alertPanelHeights: {
    [PFXAlertPanelMode.COLLAPSED]: styling.defaults.pfxAlertpanelHeight,
    [PFXAlertPanelMode.EXPANDED]: '270px',
  },
};

export const syncCSSLayout = (
  layoutMode: PFXAppLayoutMode,
  previousMode: PFXAppLayoutMode,
  menuCollapsed: boolean,
  menuHidden: boolean,
  alertsVisible: boolean,
  alertsEnabled: boolean
) => {
  styling.updateCSSVar(
    styling.vars.pfxTopmenuHeight,
    PFXLayoutConstants.topMenuHeights[layoutMode]
  );

  styling.updateCSSVar(
    styling.vars.pfxTopmenuOffset,
    PFXLayoutConstants.topMenuHeights[layoutMode]
  );

  styling.updateCSSVar(
    styling.vars.pfxCardPadding,
    PFXLayoutConstants.cardPadding[layoutMode]
  );

  styling.updateCSSVar(
    styling.vars.pfxContentPadding,
    PFXLayoutConstants.contentPadding[layoutMode]
  );

  if (alertsEnabled ?? false) {
    styling.updateCSSVar(
      styling.vars.pfxAlertpanelHeight,
      PFXLayoutConstants.alertPanelHeights[
        alertsVisible ? PFXAlertPanelMode.EXPANDED : PFXAlertPanelMode.COLLAPSED
      ]
    );
  } else {
    styling.updateCSSVar(styling.vars.pfxAlertpanelHeight, '0px');
  }

  // #10412
  styling.updateCSSVar(styling.vars.pfxToolbarDisplay, 'unset');

  styling.updateCSSVar(
    '--cfx-app-active-padding-width',
    PFXLayoutConstants.activePadding[layoutMode]
  );

  styling.updateCSSVar(
    '--cfx-filter-panel-light-gray',
    'var(--pfx-theme-smoke)'
  );

  styling.updateCSSVar(
    '--cfx-filter-panel-button-highlight-background',
    'var(--pfx-theme-blue)'
  );

  styling.updateCSSVar(
    '--cfx-dataform-field-dirty-indicator-color',
    'var(--pfx-theme-blue)'
  );

  const w = menuHidden
    ? PFXLayoutConstants.sideMenuWidths[PFXSideMenuMode.HIDDEN]
    : PFXLayoutConstants.sideMenuWidths[layoutMode][
        menuCollapsed ? PFXSideMenuMode.COLLAPSED : PFXSideMenuMode.EXPANDED
      ];
  styling.updateCSSVar(styling.vars.pfxSidemenuWidth, w);

  const root = document.body; //document.getElementById('root');
  if (root) {
    if (previousMode) {
      root.classList.remove('pfx-' + previousMode);
    }
    root.classList.add('pfx-' + layoutMode);
  }
};

export const getInitialLayoutMode = () =>
  PatternFly.layout.current() as PFXAppLayoutMode;

export const initializeLayout = (
  layoutMode: PFXAppLayoutMode,
  menuCollapsed: boolean,
  menuHidden: boolean,
  alertsVisible: boolean,
  alertPanelEnabled: boolean
) => {
  styling.updateCSSVar(
    styling.vars.pfxBreakpointTablet,
    PatternFly.patternfly.pfBreakpoints.tablet + 'px'
  );

  styling.updateCSSVar(
    styling.vars.pfxBreakpointDesktop,
    PatternFly.patternfly.pfBreakpoints.desktop + 'px'
  );

  syncCSSLayout(
    layoutMode,
    null,
    menuCollapsed,
    menuHidden,
    alertsVisible,
    alertPanelEnabled ?? false
  );
};
