import { Callout, DirectionalHint, Stack, StackItem } from "@fluentui/react";
import * as React from 'react';
import { useMemo } from "react";
import { PFXUser } from "src/models";
import { MessageThread } from "./MessageThread";
import { SendBox } from "./SendBox";
import { TypingIndicator } from "./TypingIndicator";
import { chatId } from "./chatId";
import { closeChat } from "./closeChat";
import { isEscapeKeySequence } from "./isEscapeKeySequence";
import { ChatOptions, useChat } from "./useChat";

type ChatProps = {
    user: PFXUser,
    apiUrl: string
}

export function Chat({ user, apiUrl }: ChatProps): React.ReactElement {

    const chatOptions: ChatOptions = useMemo(() => {
        return {
            bot: {
                userId: "projectflow-bot",
                displayName: "ProjectFlow"
            },
            user: {
                userId: user.userName,
                displayName: user.name
            },
            apiUrl
        }
    }, [user.name, user.userName, apiUrl])

    const { chatMessages, sendChatMessage, typingUser, newTopic } = useChat(chatOptions)

    return (
        <Callout role="dialog" target={`#${chatId}`} directionalHint={DirectionalHint.bottomRightEdge} gapSpace={1} styles={{
            root: {
                height: "60ch",
                width: "50ch",
                padding: "1ch"
            }
        }} onKeyDown={(event) => {
            if (isEscapeKeySequence(event)) {
                closeChat(window.store.dispatch)
            }
        }}>
            <Stack verticalFill grow styles={{ root: { overflow: "hidden" } }}>
                <StackItem grow styles={{ root: { overflow: "auto", marginBottom: "1ch" } }}>
                    <MessageThread messages={chatMessages} />
                </StackItem>
                <TypingIndicator typingUser={typingUser} />
                <SendBox onSendMessage={sendChatMessage} onNewTopic={newTopic} />
            </Stack>
        </Callout>
    )
}
