import * as React from 'react';
import VerticalNavIconBar from 'patternfly-react/dist/js/components/VerticalNav/VerticalNavIconBar';
import { Dropdown } from 'patternfly-react/dist/js/components/Dropdown';
import { Icon } from 'patternfly-react/dist/js/components/Icon';
import MenuItem from 'patternfly-react/dist/js/components/MenuItem/MenuItem';
import { PFXUser } from '../../models';
import PFXMenuItem, { PFXMenuItemType } from '../../models/PFXMenuItem';
import { PFXWeb } from 'src/api/webApi';
import { clearUserImage } from 'src/redux/actionCreators';
import { PFXAppLayoutMode } from 'src/styling/layout';
interface Props {
  menus: PFXMenuItem[];
  location: Location;
  navigateTo: any;
  contextUser: PFXUser;
  contextWeb: PFXWeb;
  layoutMode: PFXAppLayoutMode;
}

const dropdownComponentClass = props => (
  <li className={props.className}>{props.children}</li>
);



export const TopMenu: React.FunctionComponent<Props> = props => {
  const replacePath = (to: string, slug?: string) => {
    // replaces ':slug' placeholders with actual slug ids
    return to.replace(':slug', slug ? slug : props.contextWeb.slug);
  };


  return props.menus && (
    /* nav navbar-nav navbar-right navbar-iconic */
    /* TODO: Handles scopes */
    <VerticalNavIconBar className="nav navbar-nav navbar-utility">
      {props.menus
        .filter(function (item: PFXMenuItem) {
          return (
            item.hidden !== true &&
            item.menu === 'Top' &&
            ((props.contextWeb && item.scope === props.contextWeb.type) ||
              item.scope === 'Global' ||
              item.scope === 'Public' ||
              item.scope === 'User')
          );
        })
        .map((item, index) => {
          const itemTitle =
            item.to === '/User'
              ? props.contextUser.name
              : item.title;


          const subItems = item.subItems ? item.subItems
            .filter(function (secondaryItem: PFXMenuItem) {
              return (
                secondaryItem.hidden !== true &&
                secondaryItem.menu === 'Top' &&
                ((props.contextWeb &&
                  secondaryItem.scope === props.contextWeb.type) ||
                  secondaryItem.scope === 'Global' ||
                  secondaryItem.scope === 'User' ||
                  secondaryItem.scope === 'Public')
              );
            }) : null;

          return subItems && subItems.length > 0 && (
            <Dropdown
              componentClass={dropdownComponentClass}
              id={'topmenu-' + index}
              key={index}
            >
              <Dropdown.Toggle
                className="nav-item-iconic"
                bsStyle="link"
                noCaret
                active={item.to === props.location.pathname}
                onClick={() =>
                  item.noNavigation ? null : props.navigateTo(item)
                }
              >
                {item.to === '/User' && (props.contextUser.profileImage ?? '') !== '' && !props.contextUser.profileImage.includes('ignore') ? (
                  <img
                    src={props.contextUser.profileImage}
                    className={'pfx-profile-image'}
                    onError={() => window.store.dispatch(clearUserImage())/* #6951 */}
                    title={item.title}
                  />
                ) : (
                  <Icon type="pf" name={item.iconClass} title={item.title} />
                )}{' '}
                {props.layoutMode !== PFXAppLayoutMode.TABLET && (
                  <span className="dropdown-title">{itemTitle}</span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className={'pfx-topmenu'}>
                {subItems.map((secondaryItem, subIndex) =>
                  secondaryItem.itemType === PFXMenuItemType.divider ? (
                    <MenuItem
                      key={subIndex}
                      className={'pfx-topmenu-separator'}
                      disabled={true}

                    />
                  ) : (
                    <MenuItem
                      key={subIndex}
                      title={secondaryItem.title}
                      onClick={(event: Event) => {
                        props.navigateTo(secondaryItem)
                      }}
                      dataID={'pfx-menu'}
                      href={replacePath(secondaryItem.to, props.contextWeb.slug)}
                    >
                      {secondaryItem.title}
                    </MenuItem>
                  )
                )
                }
              </Dropdown.Menu>
            </Dropdown>
          );

        })}
      {props.children}
    </VerticalNavIconBar>
  );
};

/*

export class TopMenuFAB extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {

    const customButton = (props: IButtonProps) => {
      const buttonOnMouseEnter = () => console.log(`${props.text} hovered`);
      return (
        <CommandBarButton
          onMouseEnter={buttonOnMouseEnter}
          {...props}
          styles={{
            ...props.styles,
            icon: { color: 'red' }
          }}
        />
      );
    };

    // const { items, overflowItems, farItems } = this.props;
    const cbStyles: ICommandBarStyles = {
      // primarySet: { 'width': '200px' }
    };

    return (
      <VerticalNavIconBar className="nav navbar-nav navbar-utility" >
        <CommandBar
          overflowButtonProps={{
            menuProps: {
              items: [], // Items must be passed for typesafety, but commandBar will determine items rendered in overflow
              isBeakVisible: true,
              beakWidth: 10,
              gapSpace: 10
            }
          }}
          buttonAs={customButton}
          items={this.props.menus.filter(function (item: PFXMenuItem) {
            return (
              (item.hidden !== true) &&
              (item.menu === 'Top')
            );
          }).map((item, key) => {
            return {
              ...item, key: item.to, name: item.title
            };
          })}
          // overflowItems={overflowItems}
          // farItems={farItems}
          ariaLabel={'Use left and right arrow keys to navigate between commands'}
          styles={cbStyles}
        />
      </VerticalNavIconBar>
    );
  }

}

*/
