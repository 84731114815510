import * as Msal from '@azure/msal-browser';
// import { ClientAuthErrorMessage } from 'msal/lib-commonjs/error/ClientAuthError';
import { PFXUser } from 'src/models';
// import { PFXStorage } from 'src/Root';
import { PFXHelpers } from '../utils/PFXHelpers';
import { Auth } from './Auth';

// reference: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-browser-samples/TypescriptTestApp2.0/src/AuthModule.ts

export function createUserImpersonationApiScopeName(
  resource: string,
  resourceMap: { [resourceId: string]: string }
) {
  let rid = PFXHelpers.getOrigin(resource);
  for (const key in resourceMap) {
    if (resource.substr(0, key.length).toUpperCase() === key.toUpperCase()) {
      rid = resourceMap[key];
    }
  }
  return rid + '/user_impersonation';
}

export type AzureAuthority = 'common' | 'organizations' | 'tenant';
export class Msal2Auth extends Auth {
  public msalInstance: Msal.PublicClientApplication;
  private account: Msal.AccountInfo;

  constructor(
    tenantId: string,
    azureAadAppid: string,
    redirectUri: string,
    user: PFXUser,
    resourceId: string,
    authority?: AzureAuthority
  ) {
    super(azureAadAppid, redirectUri, tenantId, user, resourceId);

    const msalConfig: Msal.Configuration = {
      auth: {
        clientId: azureAadAppid,
        redirectUri: redirectUri,
        authority:
          authority == 'tenant'
            ? 'https://login.microsoftonline.com/' + tenantId
            : 'https://login.microsoftonline.com/' + authority,
        postLogoutRedirectUri: PFXHelpers.getOrigin(redirectUri),
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    };

    this.msalInstance = new Msal.PublicClientApplication(msalConfig);
  }

  public async loginPopup(selectAccount?: boolean) {
    const request: Msal.SilentRequest = {
      scopes: [this.getScope(this.resourceId)],
      account: this.account,
      extraQueryParameters: selectAccount ? { prompt: 'select_account' } : null,
    };

    try {
      return (await this.msalInstance.acquireTokenSilent(request)).accessToken;
    } catch (error1) {
      console.debug(
        'AUTH - silent token acquisition failed, continuing popup flow',
        error1
      );
      try {
        return (await this.msalInstance.acquireTokenPopup(request)).accessToken;
      } catch (error2) {
        console.error('AUTH - error', error2);
        return null;
      }
    }
  }

  public loginRedirect(selectAccount?: boolean) {
    const request: Msal.SilentRequest = {
      scopes: [this.getScope(this.resourceId)],
      account: this.account,
      extraQueryParameters: selectAccount ? { prompt: 'select_account' } : null,
    };

    this.msalInstance.loginRedirect(request);
  }

  public isCallback(hash: string) {
    return false;
  }

  public handleWindowCallback(hash?: string) {
    console.debug('MSAL handleWindowCallback not implemented');
  }

  public isSignedIn() {
    return this.account ? true : false;
  }

  public getUser(activeUser?: string) {
    if (this.account || activeUser) {
      // if token is provided, update local instance (typically due to account change)
      if (activeUser) {
        this.account = this.msalInstance.getAccountByUsername(activeUser);
      }

      return {
        userName: this.account.username,
        name: this.account.name,
      };
    } else {
      const currentAccounts = this.msalInstance.getAllAccounts();

      if (!currentAccounts || currentAccounts.length === 0) {
        // User did not sign in
        console.warn('AUTH - user did not sign in');
        return null;
      } else if (currentAccounts.length > 1) {
        // More than one user signed in, find desired user with getAccountByUsername(username)
        if (this.user) {
          this.account = this.msalInstance.getAccountByUsername(
            this.user.userName
          );
        } else if (window.user) {
          this.account = this.msalInstance.getAccountByUsername(
            window.user.userName
          );
        }

        if (!this.account) {
          // use first account
          this.account = currentAccounts[0];
        }
      } else {
        this.account = currentAccounts[0];
      }

      return {
        userName: this.account.username,
        name: this.account.name,
      };
    }
  }

  public clearCache() {
    // necessary with msal?
  }

  public logOut() {
    this.msalInstance.logout();
  }

  public getScope(resourceId: string): string {
    const resourceMap: { [resourceId: string]: string } = {};
    resourceMap[this.resourceId] = this.resourceId;

    return createUserImpersonationApiScopeName(resourceId, resourceMap);
  }

  public acquireTokenRedirect(resourceId: string) {
    if (this.isSignedIn()) {
      const request: Msal.SilentRequest = {
        scopes: [this.getScope(resourceId)],
        account: this.account,
      };

      this.msalInstance
        .acquireTokenSilent(request)
        .then(/* result => return result.accessToken */)
        .catch(() => {
          this.msalInstance.acquireTokenRedirect(request);
        });
    }
  }

  public acquireToken(resourceId: string) {
    return new Promise<string>((resolve) => {
      if (this.isSignedIn()) {
        const request: Msal.SilentRequest = {
          scopes: [this.getScope(resourceId)],
          account: this.account,
        };

        this.msalInstance
          .acquireTokenSilent(request)
          .then((response) => {
            resolve(response.accessToken);
          })
          .catch((err1: Msal.AuthError) => {
            if (err1 instanceof Msal.InteractionRequiredAuthError) {
              console.debug('AUTH Interaction Required', err1);
              window.auth.loginRedirect();
            }
          });
      } else {
        console.debug(
          'AUTH Login Required',
          'User is not logged in, starting redirection flow'
        );
        window.auth.loginRedirect();
      }
    });
  }
}
