import React from 'react';

export interface QueryStringModifier {
  [queryParameter: string]: string;
}

export interface Filter {
  name: string;
  value: string;
}

export interface IFilterItemIndex {
  [index: string]: any; // FObject;
}

export interface IFilter {
  [name: string]: string | null;
}

export function createIFObjectNull(
  name: string,
  lcid: number = 1033,
  type: number = 28
) {
  return {
    name: name,
    type: type,
    subtype: '',
    value: '',
    lcid: lcid,
    displayValue: '',
    isNull: true,
    multiple: false,
  };
}

export interface IAppProperties {
  origin: string;
  sourceUrl: string;
  AppId: string; // Unique instance id
  AppName: string; // Tool name
  Id: string; // WP Context Id
  LCID: number;
  WebId: string;
  WebType: string; // CoreFlow web type
  WebUrl: string; // Web url including trailing forward slash - window.location.href
  Tool: string;
}

export enum SplashScreen {
  Shimmer = 'Shimmer',
  Spinner = 'Spinner',
  None = 'None',
}

export interface IAppCustomizer {
  splashScreenRenderer?: (appProperties: IAppProperties) => JSX.Element | null;
  splashScreen?: SplashScreen;
}

export type AcquireTokenCallback = (
  resourceId: string,
  reason: number
) => Promise<string>;

export const AquireToken: AcquireTokenCallback = async (
  resourceId: string,
  reason: number
): Promise<string> => {
  /*
  const tokenForCaaS = await window.auth.acquireToken(
    window.ProjectFlowX.coreflow.resourceId
  );
  */
  /* ADAL
  const tokenForCaaS = await new Promise<string>((resolve, reject) => {
    window.pfxAuthenticationContext.acquireToken(
      window.ProjectFlowX.coreflow.resourceId,
      (errorDesc: string | null, token: string | null, error: any) => {
        if (token && token.length > 0) {
          resolve(token);
        } else {
          console.error('CoreFlow AquireToken Error', errorDesc, error);
        }
      }
    );
  });
  */
  if (reason === 1) {
    // standard token request
    // return tokenForCaaS;
    return window.auth.acquireToken(window.ProjectFlowX.coreflow.resourceId);
  } else if (reason === 2) {
    // Access Denied - brute force app reload
    window.auth.acquireTokenRedirect(window.ProjectFlowX.coreflow.resourceId);
  }
  return null;
};

export interface IWebPlatformProperties {
  caasUrl: string;
  // pageId: string,
  lcid: number;
  userName: string;
  acquireToken: AcquireTokenCallback;
}

export enum AppType {
  FilterSelector = 'FilterSelector',
  GenericWP = 'GenericWP',
  WebTools = 'WebTools',
  DataGrid = 'DataGrid',
  DataForm = 'DataForm',
  QuickNavigator = 'QuickNavigator',
  ProjNavigator = 'ProjNavigator',
  Composition = 'Composition',
}

export enum ToolType {
  FilterSelector = 'FilterSelector',
  Composition = 'Composition',
  DataGrid = 'DataGrid',
}

export enum AppEventType {
  // an app just saved it's data
  Saved = 'saved',

  // after the refresh button is pressed in selectorfilter app
  Refreshed = 'refreshed',

  // an app have data that is not stored or is back to normal
  Unsaved = 'unsaved',

  // app is processing - unsafe to unmount
  ProcessingStarted = 'processingStarted',

  // app is processing - unsafe to unmount
  ProcessingFinished = 'processingFinished',
}
/*
export interface IAppEventArgs {
  appId: string;
  appName: string;
  type: AppEventType;
  // if true then the app contains data the is not saved. If false, then it is save to navigate away
  isUnSaved: boolean;
}
*/
export enum DataFormChangeType {
  Created = 0,
  Modified = 1,
}

export interface DataFormEventData {
  editRowId: string;
  changeType: DataFormChangeType;
}
/*
export function isDataGridCustomSavedEventData(
  data: unknown
): data is DataGridCustomSavedEventData {
  if (
    typeof data === 'object' &&
    'saveType' in data &&
    typeof data.saveType === 'string' &&
    data.saveType === 'custom-save'
  )
    return true;
  return false;
}

export function isDataGridBuiltinSavedEventData(
  data: unknown
): data is DataGridBuiltinSavedEventData {
  if (
    typeof data === 'object' &&
    'saveType' in data &&
    typeof data.saveType === 'string' &&
    data.saveType === 'built-in-save'
  )
    return true;
  if (
    typeof data === 'object' &&
    'saveType' in data &&
    typeof data.saveType === 'string' &&
    data.saveType === 'built-in-save-with-side-effects'
  )
    return true;
  return false;
}
*/

export type DataGridBuiltinSavedEventData = {
  saveType: 'built-in-save' | 'built-in-save-with-side-effects';
  changedRows: Record<string, RowChangedEntry>;
  failedRowChanges: Record<string, RowChangeError>;
};

export type DataGridCustomSavedEventData = {
  saveType: 'custom-save';
};

type RowChangedEntry = {
  editRowId: string;
  changeType: 'created' | 'updated';
};

type RowChangeError = {
  editRowId: string;
  changeType: 'created' | 'updated';
  message: string;
  error: unknown;
};
export interface IAppEventArgs<EventData = unknown> {
  appId: string;
  appName: string;
  type: AppEventType;
  // if true then the app contains data the is not saved. If false, then it is save to navigate away
  isUnSaved: boolean;
  data: EventData;
  toolId: string;
}

export interface IAppEditState {
  parentId?: string;
  appId: string;
  appName: string;
  isUnSaved: boolean;
}

export interface IAppEditStates {
  [appId: string]: IAppEditState;
}

export interface IAppProcessingState {
  parentId?: string;
  appId: string;
  appName: string;
  isProcessing: boolean;
}

export interface IAppProcessingStates {
  [appId: string]: IAppProcessingState;
}

export const firstUnsavedApp = (
  appEditStates: IAppEditStates
): IAppEditState => {
  if (appEditStates) {
    const unsavedAppId = Object.keys(appEditStates).find(
      (value) => appEditStates[value].isUnSaved
    );
    if (unsavedAppId) {
      return appEditStates[unsavedAppId];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getUnsavedApp = (
  appEditStates: IAppEditStates,
  parentId: string // format "AppName::ContextId"
): IAppEditState => {
  if (appEditStates) {
    const unsavedAppId = Object.keys(appEditStates).find((appId) => {
      const app = appEditStates[appId];
      return app.isUnSaved === true && app.parentId === parentId;
    });
    if (unsavedAppId) {
      return appEditStates[unsavedAppId];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getProcessingApp = (
  appProcessingStates: IAppProcessingStates,
  parentId: string // format "AppName::ContextId"
): IAppProcessingState => {
  if (appProcessingStates) {
    const processingAppId = Object.keys(appProcessingStates).find((appId) => {
      const app = appProcessingStates[appId];
      return app.isProcessing === true && app.parentId === parentId;
    });
    if (processingAppId) {
      return appProcessingStates[processingAppId];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export interface IAppEventCallback {
  (eventArgs: IAppEventArgs): void;
}

export interface IWebAppProperties extends IWebPlatformProperties {
  appId: string;
  appName: AppType;
  toolName: ToolType;
  toolId: string;
  lcid: number;
  webId: string;
  webUrl?: string;
  pageType?: string;
  pageIdentification?: string;
  onChange?: () => void;
  webAttr?: string;
  filter?: IFilter;
  queryStringModifier?: QueryStringModifier;
  appCustomizer?: IAppCustomizer;
  caasUrl: string;
  userName: string;
  acquireToken: AcquireTokenCallback;
  event: IAppEventCallback;
}

export interface IPageProperties extends IWebPlatformProperties {
  webId: string;
  pageId: string;
  lcid: number;
  caasUrl: string;
  onChange?: () => void;
  onFilterChange?: (filter: IFilterItemIndex) => void;
  queryStringModifier?: QueryStringModifier;
  userName: string;
  appCustomizer?: IAppCustomizer;
  acquireToken: AcquireTokenCallback;
}

export interface IChannelSubscription {
  unsubscribe(): void;
}

export interface AutomationMessage {
  FlowId: string;
  FlowName: string;
  EventName: string;
  ActionId: string;
  ActionName: string;
  ActionTypeName: string;
  Message: any;
}

export declare namespace cfx_web_ns {
  export class Page extends React.Component<IPageProperties, {}> {
    constructor(props: IPageProperties);
  }

  export class WebApp extends React.Component<IWebAppProperties, {}> {
    constructor(props: IWebAppProperties);
  }

  export class WebPlatform {
    public messenger(
      receiver: (message: AutomationMessage) => void
    ): IChannelSubscription;
  }

  export function getInstance(
    properties: IWebPlatformProperties
  ): Promise<WebPlatform>;

  enum PublicEventType {
    CookieTokenSet = 'cookie-token-set',
  }

  interface PublicEvent {
    type: PublicEventType;
  }

  interface CookieTokenSetEvent extends PublicEvent {
    type: PublicEventType.CookieTokenSet;
    timestamp: number;
  }

  class EventSubscription {
    public unsubscribe(): void;
    public get closed(): boolean;
  }

  export interface CallerInfo {
    appId: string;
    initiator: string;
  }

  type FObject = any;

  export interface FilterItems {
    [index: string]: FObject;
  }

  export interface FilterMessage {
    filter: FilterItems;
    appId: string;
    initiator: string;
  }

  export interface Unsubscribable {
    unsubscribe(): void;
  }

  export class Public {
    public static clearCurrentFilterAndPublishNewFilter(
      callerInfo: CallerInfo,
      filter: FilterMessage
    ): void;
    public static disposeGlobals(): void;
    public static getFilterAsync(): Promise<FilterMessage>;
    public static prepareNavigation(callerInfo: CallerInfo | null): void;
    public static prepareNavigationWithFilter(
      callerInfo: CallerInfo | null,
      filter: FilterMessage
    ): void;
    public static publishFilter(
      callerInfo: CallerInfo,
      filter: FilterMessage
    ): void;
    public static setAllFilterPropertiesToNullAndPublishFilter(
      callerInfo: CallerInfo,
      filter: FilterMessage
    ): void;
    public static setConfigMode(configMode: boolean, persist: boolean): void;
    public static setDevMode(devMode: boolean, persist: boolean): void;
    public static setEnvironmentMode(
      options: { configMode: boolean; logMode: boolean; safeMode: boolean },
      persist: boolean
    ): void;
    public static setFilterPropertiesToNull(
      callerInfo: CallerInfo | null,
      ...properties: string[]
    ): void;
    public static clearFilterPropertiesSilently(
      callerInfo: CallerInfo | null,
      ...properties: string[]
    ): void;
    public static setLogMode(logMode: boolean, persist: boolean): void;
    public static setSafeMode(safeMode: boolean, persist: boolean): void;
    public static subscribeToConfigMode(
      onNext: (configMode: boolean) => void
    ): Unsubscribable;
    public static subscribeToCookieTokenSetEvents(
      onNext: (value: CookieTokenSetEvent) => void
    ): EventSubscription;
    public static subscribeToEnvironmentMode(
      onNext: (options: {
        configMode: boolean;
        logMode: boolean;
        safeMode: boolean;
      }) => void
    ): Unsubscribable;
    public static subscribeToFilter(
      onNext: (filter: FilterMessage) => void
    ): Unsubscribable;
    public static subscribeToFilterOnce(
      onNext: (filter: FilterMessage) => void
    ): void;
    public static subscribeToLogMode(
      onNext: (logMode: boolean) => void
    ): Unsubscribable;
    public static subscribeToSafeMode(
      onNext: (safeMode: boolean) => void
    ): Unsubscribable;
  }
}
