import { FontWeights, Stack, StackItem, Text } from '@fluentui/react';
import { format } from 'date-fns';
import * as React from 'react';
import { ReactElement, useEffect } from 'react';
import { ChatMessage } from './ChatMessage';

type MessageThreadProps = {
    messages: ChatMessage[]
}

export function MessageThread({ messages }: MessageThreadProps): React.ReactElement {
    const scrollToEndRef = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
        scrollToEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [messages])

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "1ch"
        }}>
            {messages.map(({ content, mine, senderDisplayName, createdOn, messageId }) => (<Message key={messageId} content={content} mine={mine} senderDisplayName={senderDisplayName} createdOn={createdOn} />))}
            <div ref={scrollToEndRef}></div>
        </div>
    )
}


type MessageProps = {
    content: string;
    mine: boolean;
    senderDisplayName: string;
    createdOn: Date;
}


const botMessageStyle: React.CSSProperties = {
    backgroundColor: "var(--pfx-theme-lightgray)",
    alignSelf: "start",
    padding: "1ch",
    marginRight: "6ch",
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px"
}

const myMessageStyle: React.CSSProperties = {
    backgroundColor: "var(--pfx-theme-lightblue)",
    alignSelf: "end",
    padding: "1ch",
    marginLeft: "6ch",
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px"
}


function Message({ content, mine, senderDisplayName, createdOn }: MessageProps): ReactElement {
    return (
        <div style={mine ? myMessageStyle : botMessageStyle}>
            <Stack horizontal grow>
                <StackItem grow>
                    {!mine && <Text variant='small' style={{ fontWeight: FontWeights.semibold }}>{senderDisplayName}</Text>}
                </StackItem>
                <StackItem align="end">
                    <Text variant='small' style={{ fontWeight: FontWeights.semibold }}>{format(createdOn, "HH:mm:ss")}</Text>
                </StackItem>
            </Stack>
            <Text>{content}</Text>
        </div>
    )
}