import { useCallback, useState } from 'react';
import { ChatMessage } from './ChatMessage';
import { CommunicationParticipant } from './CommunicationParticipant';

export type ChatOptions = {
  bot: CommunicationParticipant;
  user: CommunicationParticipant;
  apiUrl: string;
};

export function useChat(options: ChatOptions) {
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([
    {
      content: `Hej ${options.user.displayName} ✋ Hvad kan jeg g\øre for dig i dag?`,
      mine: false,
      messageId: crypto.randomUUID(),
      createdOn: new Date(),
      messageType: 'chat',
      contentType: 'text',
      senderDisplayName: 'ProjectFlow',
    },
  ]);

  const [typingUser, setTypingUser] = useState<CommunicationParticipant>(null);

  const sendChatMessage = useCallback(
    async (content: string) => {
      setChatMessages((current) => [
        ...current,
        {
          messageType: 'chat',
          contentType: 'text',
          senderId: options.user.userId,
          senderDisplayName: options.user.displayName,
          messageId: crypto.randomUUID(),
          content,
          createdOn: new Date(),
          mine: true,
          attached: false,
          status: 'seen',
        },
      ]);

      setTypingUser({
        userId: options.bot.userId,
        displayName: options.bot.displayName,
      });

      const responseMessage = await sendMessage(
        content,
        options.user.userId,
        options.apiUrl
      );

      setTypingUser(null);

      if (isChatResponse(responseMessage))
        setChatMessages((current) => [
          ...current,
          {
            messageType: 'chat',
            contentType: 'text',
            senderId: options.bot.userId,
            senderDisplayName: options.bot.displayName,
            messageId: crypto.randomUUID(),
            content: responseMessage.response,
            createdOn: new Date(),
            mine: false,
            attached: false,
            status: 'seen',
          },
        ]);

      console.log(responseMessage);
    },
    [options]
  );

  const newTopic = useCallback(async () => {
    const responseMessage = await deleteCache(options.apiUrl);

    if (isDeleteCacheResponse(responseMessage))
      setChatMessages((current) => [
        {
          messageType: 'chat',
          contentType: 'text',
          senderId: options.bot.userId,
          senderDisplayName: options.bot.displayName,
          messageId: crypto.randomUUID(),
          content: `Jeg er klar til at tale om et nyt emne.`,
          createdOn: new Date(),
          mine: false,
          attached: false,
          status: 'seen',
        },
      ]);
  }, [options]);

  return {
    chatMessages,
    typingUser,
    sendChatMessage,
    newTopic,
  };
}

type ChatResponse = {
  response: string;
  aiIntent: string;
};

function isChatResponse(arg: unknown): arg is ChatResponse {
  if (typeof arg !== 'object') return false;
  if (
    'response' in arg &&
    typeof arg.response === 'string' /*&&
    'result_type' in arg &&
    typeof arg.result_type === 'string' &&
    (arg.result_type === 'new' || arg.result_type === 'cached')*/
  )
    return true;
  return false;
}

async function sendMessage(content: string, userId: string, apiUrl: string) {
  const currentUrl = window.location.href;
  const url = `${apiUrl}/Chat/generate-response`;
  const result = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      Input: content,
      CurrentUrl: currentUrl,
      CurrentUserId: userId,
    }),
  });

  return await result.json();
}

async function deleteCache(apiUrl: string) {
  const url = `${apiUrl}/Data/Delete-Data`;
  const result = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      DatabaseName: 'database',
      ContainerName: 'vectorcache',
    }),
  });

  if (result.status !== 200) throw new Error(result.statusText);

  return await result.json();
}

type DeleteCacheResponse = {
  response: null;
  status: true;
};

function isDeleteCacheResponse(arg: unknown): arg is DeleteCacheResponse {
  if (typeof arg !== 'object') return false;
  if (
    'response' in arg &&
    typeof arg.response === 'object' &&
    arg.response === null &&
    'status' in arg &&
    typeof arg.status === 'boolean' &&
    arg.status === true
  )
    return true;
  return false;
}
