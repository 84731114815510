import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { initializeIcons } from '@fluentui/react';
import { loadTheme } from '@fluentui/style-utilities';
import values, { Styles } from './exports.scss';
import { PFXHelpers } from 'src/utils/PFXHelpers';

// The initial variable values - useful for runtime resets
// Deduced from variables.scss,
export const defaults = values;

// Variable names of the actual css variables (camel cased)
// Deduced from variables.scss,
export const vars = PFXHelpers.createEnum<Styles>(values);

// Transform camelCase to CSS variable format
export const toCSSVar = (name: string): string => {
  if (name.startsWith('--')) {
    // If the string already starts with '--', assume it's already a CSS variable
    return name;
  }

  return (
    '--' +
    name
      .replace(/([A-Z])/g, '-$1') // Insert a hyphen before each uppercase letter
      .toLowerCase()
  ); // Convert the entire string to lowercase
};

// Update runtime css variable
// Variable name formats supported: css "--some-thing"  and camel case "someThing"
export const updateCSSVar = (variable: string, value: string) => {
  // we dont support setting CSS vars to undefined, as behaviour is very hard to predict!
  if (value) {
    // variable name may be camelCased..
    const cssVar = toCSSVar(variable);
    // avoid unnecessary dom update
    if (document.documentElement.style.getPropertyValue(cssVar) !== value) {
      document.documentElement.style.setProperty(cssVar, value);
    }
  }
};

// Initializing icons from fluent ui -
// Due to double registration in CoreFlow, we get warnings about that.
// disableWarnings is suggested as the fix for that, but doesn't seem to do much..
export const initializeIconAssets = (): void => {
  if (true) {
    initializeIcons(undefined, { disableWarnings: true });
    initializeFileTypeIcons(undefined, { disableWarnings: true });
  }
};

export const setDefaultFluentUITheme = (): void => {
  // TODO: Initialize fabric theme from more CSS Vars
  loadTheme({
    palette: {
      themePrimary: defaults.pfxThemeBlue,
      themeLighterAlt: '#f6fbfe',
      themeLighter: '#dceff9',
      themeLight: '#bee2f4',
      themeTertiary: '#82c7ea',
      themeSecondary: '#4eafe0',
      themeDarkAlt: '#3395c5',
      themeDark: '#2b7ea7',
      themeDarker: '#205d7b',
      neutralLighterAlt: '#f8f8f8',
      neutralLighter: '#f4f4f4',
      neutralLight: '#eaeaea',
      neutralQuaternaryAlt: '#dadada',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c8c8',
      neutralTertiary: '#acb2ba',
      neutralSecondary: '#949ba3',
      neutralPrimaryAlt: '#7c848d',
      neutralPrimary: '#292e34',
      neutralDark: '#505760',
      black: '#3c4249',
      white: '#ffffff',
    },
  });
};
