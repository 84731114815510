import jsonRequest from './jsonRequest';
import { COREFLOW_API } from '../Root';
import { PFXHelpers } from 'src/utils/PFXHelpers';

const QUERY_PFX_NAVIGATOR = '/PFX_Navigator/data';

export enum PFXWebType {
  PORTAL = 'PFX_Portal',
  PMO = 'PFX_PMO',
  PROJECT = 'PFX_Project',
}

export interface PFXWeb {
  title?: string;
  guid?: string;
  type?: PFXWebType;
  slug?: string; // i.e. domain.com/slug/path, e.g. projectflow.com/P-000001/Tasks/Overview
  description?: string;
  /* lcid?: number; */
  keywords?: string;
  url?: string;
  archived?: boolean;
  activeZones?: string;
  lastModified?: Date;
  alertPanelEnabled?: boolean;
}

const mapFields = (item: any): PFXWeb => {
  const web: PFXWeb = {
    guid: item._Id as string,
    slug: item.ProjectId as string,
    title: PFXHelpers.htmlDecode(item._Title as string),
    type: item._WebType as PFXWebType,
    description: PFXHelpers.htmlDecode(item._Description as string),
    /* lcid: item._LCID as number, */
    keywords: item.Keywords,
    url: item._Url as string,
    archived: item.Archived as boolean,
    activeZones: item.ActiveZones as string,
    lastModified: PFXHelpers.ticksToDate(item.LastModified),
    alertPanelEnabled: item.AlertPanelEnabled as boolean,
  };
  return web;
};

const websApi = {
  fetchWebs(contextWeb: PFXWeb): Promise<void | PFXWeb[]> {
    return jsonRequest(
      COREFLOW_API + QUERY_PFX_NAVIGATOR,
      contextWeb,
      { mode: 'simple' },
      'GET'
    )
      .then(
        // Success!
        (data): PFXWeb[] => data.map((item) => mapFields(item))
      )
      .catch((error) => {
        console.error(
          'contextApi.fetchWeb ERROR',
          { RequestUrl: COREFLOW_API + QUERY_PFX_NAVIGATOR },
          error
        );
      });
  },

  fetchWeb(slugOrId: string, contextWeb: PFXWeb): Promise<void | PFXWeb> {
    // '/' really means "", i.e. no slug, but CoreFlows filtering is broken as "" == null, which in service means all webs

    return jsonRequest(
      COREFLOW_API + QUERY_PFX_NAVIGATOR,
      contextWeb,
      { mode: 'simple', consumerfilter: '{"slugOrId":"' + slugOrId + '"}' },
      'GET'
    )
      .then(
        // Success!
        (data): PFXWeb => data.map((item) => mapFields(item))[0]
      )
      .catch((error) => {
        console.error(
          'contextApi.fetchWeb ERROR',
          { RequestUrl: COREFLOW_API + QUERY_PFX_NAVIGATOR },
          error
        );
      });
  },
};

export default websApi;
