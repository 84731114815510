import * as React from 'react';
import PFXMenuItem, { PFXContentPlacement, PFXMenuItemType } from 'src/models/PFXMenuItem';
import {
  PFXLocalizedTerms,
  PFXShared,
  PFXMessages
} from 'src/api/localizationApi';
import ReactHtmlParser from 'react-html-parser';
import {
  Pivot,
  PivotItem
} from '@fluentui/react/lib/Pivot';
import AboutPage from 'src/components/Misc/About';
import { IconButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import CoreFlowAppBase from '../CoreFlow/CoreFlowAppBase';
import { AppType, IFilter, ToolType } from '../CoreFlow/CoreFlow';
import { PFXWeb } from 'src/api/webApi';
import { PFXUser } from 'src/models';
import { PFXHelpers } from 'src/utils/PFXHelpers';
import { PFXForm } from 'src/api/formsApi';
import { PFXAlert } from './Alert';
import { PFXComponentFactory } from 'src/utils/PFXComponentFactory';
import { devMode } from 'src/Root';
import { PFXAppLayoutMode, PFXRenderZone } from 'src/styling/layout';
import { PFXSystemInfo } from 'src/api/systemApi';

interface Props {
  contextWeb: PFXWeb;
  contextUser: PFXUser;
  contextForm: PFXForm;
  lcid: number;
  terms: PFXLocalizedTerms;
  currentPath: PFXMenuItem[];
  onClose: () => void;
  alert: PFXAlert;
  dynamicTab: PFXMenuItem;
  filter?: IFilter;
  systemInfo: PFXSystemInfo;
  layoutMode?: PFXAppLayoutMode;
}

interface State {
  refreshStamp: number;
}

export class InlinePanel extends React.PureComponent<Props, State> {
  private item: PFXMenuItem;
  private helpText: string;

  constructor(props: Props) {
    super(props);
    this.state = { refreshStamp: 0 };
    this.updateData = this.updateData.bind(this);
    this.updateData(props);
  }

  updateData(props: Props) {
    if (props && props.currentPath && props.currentPath.length > 0) {

      if (this.props.contextForm) {
        // Fall back to item category
        this.item = props.currentPath[props.currentPath.length - 2];
      } else {
        this.item = props.currentPath[props.currentPath.length - 1];
      }

      if (this.item && this.item.to) {
        const path = this.item.to as string;
        const pathEntries = path.split('/');

        const page = pathEntries[pathEntries.length - 1];
        const entry =
          page && page !== '/'
            ? 'PFX::Help::' + this.item.scope + '::' + page
            : 'PFX::Help::' + this.item.scope;

        this.helpText = ReactHtmlParser(
          this.props.terms[entry]
            ? this.props.terms[entry]
            : this.props.terms[PFXMessages.NODOCUMENTATION]
        );
      }
    } else {
      this.item = null;
      this.helpText = null;
    }
  }

  render() {
    // Ok as we're using PureComponent, otherwise we'd have to look into memoization
    this.updateData(this.props);
    return (
      <>
        {this.props.terms && (
          <>
            {(
              <IconButton
                iconProps={{ iconName: 'ChromeClose' }}
                title={this.props.terms[PFXShared.CLOSE]}
                ariaLabel={this.props.terms[PFXShared.CLOSE]}
                onClick={this.props.onClose}
                styles={{
                  root: {
                    position: 'absolute',
                    right: '20px',
                    zIndex: 1000
                  },
                  icon: {
                    fontSize: 'smaller'
                  }

                }}
              />
            )}

            <Pivot linkFormat={'links'} /* style={{ float: 'left' }} causes weird About layout */>
              {this.props.dynamicTab && (
                <PivotItem headerText={this.props.dynamicTab.title} alwaysRender>
                  {PFXComponentFactory(
                    this.props.dynamicTab.component,
                    this.props.dynamicTab.componentProps,
                    PFXRenderZone.HELPPANEL,
                    this.props.dynamicTab
                  )}
                </PivotItem>
              )}
              {this.item && (
                <PivotItem headerText={this.props.terms[PFXShared.HELP]}>
                  <div
                    /* className="card-pf card-pf-accented" */
                    style={{
                      marginTop: '10px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      paddingLeft: '8px'
                    }}
                  >
                    <h3>
                      <Icon
                        className={this.item.iconClass}
                        style={{
                          fontSize: '1em',
                          float: 'left',
                          marginRight: '5px'
                        }}
                      />
                      {this.props.terms['PFX::System::' + this.item.scope] +
                        ' > ' +
                        this.item.title}
                    </h3>
                    {(this.item.hasCustomContent && this.item.hasCustomContent.includes(PFXContentPlacement.HELPPANEL_TOP) || this.item.itemType === PFXMenuItemType.inline) && (
                      <>
                        <CoreFlowAppBase
                          contextWeb={this.props.contextWeb}
                          contextUser={this.props.contextUser}
                          lcid={this.props.lcid}
                          appName={AppType.Composition}
                          toolName={ToolType.Composition}
                          toolId={'PFX_RenderContent'}
                          fullPageLayout={true}
                          filter={{
                            PFX_Filter_Placement: PFXContentPlacement.HELPPANEL_TOP,
                            PFX_Filter_TargetId: this.item.ID,
                            PFX_Filter_Scope: this.item.scope
                          }}
                          disableUnsavedCheck={true}
                          layoutMode={this.props.layoutMode}
                        />
                        <h3>{this.props.terms[PFXShared.BUILDINHELP]}</h3>
                      </>
                    )}
                    {this.helpText}
                    <br />
                    <br />
                  </div>
                </PivotItem>
              )}
              {this.item.hasCustomContent && this.item.hasCustomContent.includes(PFXContentPlacement.HELPPANEL_NEWTAB) && (
                <PivotItem headerText={this.props.terms[PFXShared.OTHERCONTENT]}>
                  <CoreFlowAppBase
                    contextWeb={this.props.contextWeb}
                    contextUser={this.props.contextUser}
                    lcid={this.props.lcid}
                    appName={AppType.Composition}
                    toolName={ToolType.Composition}
                    toolId={'PFX_RenderContent'}
                    fullPageLayout={true}
                    filter={{
                      PFX_Filter_Placement: PFXContentPlacement.HELPPANEL_NEWTAB,
                      PFX_Filter_TargetId: this.item.ID,
                      PFX_Filter_Scope: this.item.scope
                    }}
                    disableUnsavedCheck={true}
                    layoutMode={this.props.layoutMode}
                  />
                </PivotItem>
              )}
              <PivotItem headerText={this.props.terms[PFXShared.ABOUT]}>
                <AboutPage
                  contextWeb={this.props.contextWeb}
                  contextUser={this.props.contextUser}
                  terms={this.props.terms}
                  alert={this.props.alert}
                  systemInfo={this.props.systemInfo}
                />
              </PivotItem>
              {devMode && (
                <PivotItem headerText="Translation Tool">
                  <IconButton
                    iconProps={{ iconName: 'Refresh' }}
                    label="Update"
                    title="Update"
                    ariaLabel="Update"
                    onClick={() => this.setState({ refreshStamp: PFXHelpers.dateToTicks(new Date()) })}
                  />
                  <CoreFlowAppBase
                    contextWeb={this.props.contextWeb}
                    contextUser={this.props.contextUser}
                    lcid={this.props.lcid}
                    appName={AppType.Composition}
                    toolName={ToolType.Composition}
                    toolId={'PFX_TranslationTool'}
                    dataTick={this.state.refreshStamp}
                    fullPageLayout={true}
                    filter={this.props.filter}
                    disableUnsavedCheck={true}
                    layoutMode={this.props.layoutMode}
                  />
                </PivotItem>
              )}
            </Pivot>
          </>
        )}
      </>
    );
  }
}

export default InlinePanel;
