import { Persona, PersonaSize, Stack } from '@fluentui/react';
import * as React from 'react';
import { CommunicationParticipant } from "./CommunicationParticipant";
import { Wave } from './Wave';

type TypingIndicatorProps = {
    typingUser: CommunicationParticipant;
}

export function TypingIndicator({ typingUser }: TypingIndicatorProps): React.ReactElement {

    if (!typingUser) return null

    return (
        <Stack horizontal>
            <Persona text={`${typingUser.displayName} er i gang med at svare`} id={typingUser.userId} size={PersonaSize.size8} />
            <Wave />
        </Stack>
    )
}
