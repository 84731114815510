import React from "react";
// TODO: Replace with css module.
import "./Wave.css"

export function Wave(): React.ReactElement {
    return (
        <div className="chat-wave">
            <span className="chat-dot"></span>
            <span className="chat-dot"></span>
            <span className="chat-dot"></span>
        </div>
    )
}