import jsonRequest from './jsonRequest';
import {
  COREFLOW_API,
  PublicLogin,
  PublicLogout,
  PublicNotFound,
} from '../Root';
import PFXMenuItem from '../models/PFXMenuItem';
import { PFXWeb } from './webApi';

const QUERY_PFX_NAVIGATION = '/PFX_Navigation/data';

const getNestedChildren = (
  items: PFXMenuItem[],
  parent: string
): PFXMenuItem[] => {
  var out: PFXMenuItem[] = [];
  for (var i in items) {
    if (items[i].ParentID === parent) {
      const subItems = getNestedChildren(items, items[i].ID);

      if (subItems.length) {
        items[i].subItems = subItems;
      }
      out.push(items[i]);
    }
  }
  return out;
};
/*
function flatToHierarchy(flat: PFXMenuItem[]) {
  let roots: PFXMenuItem[] = []; // things without parent

  // make them accessible by guid on this map
  let all: PFXMenuItem[] = [];

  flat.forEach(function(item: PFXMenuItem) {
    all[item.ID] = item;
  });

  // connect childrens to its parent, and split roots apart
  Object.keys(all).forEach(function(ID: string) {
    var item = all[ID];
    if (item.parent === null) {
      roots.push(item);
    } else if (item.parent in all) {
      var p = all[item.parent];
      if (!('subItems' in p)) {
        p.subItems = [];
      }
      p.subItems.push(item);
    }
  });
  console.log('roots', roots);
  // done!
  return roots;
}
*/
const navigationApi = {
  fetchMenuItems(contextWeb: PFXWeb): Promise<void | PFXMenuItem[]> {
    return jsonRequest(
      COREFLOW_API + QUERY_PFX_NAVIGATION,
      contextWeb,
      { mode: 'simple' },
      'GET'
    )
      .then((data): PFXMenuItem[] => {
        // Always make sure we have the public pages available
        let staticItems: PFXMenuItem[] = [
          PublicLogin,
          PublicLogout,
          PublicNotFound,
        ];

        const items = data.map((item: PFXMenuItem) => {
          let resultItem: PFXMenuItem = {
            ...item,
            iconClass: 'pficon ' + item.iconClass,
            componentProps: JSON.parse(item.componentProps as string),
            externalLinkProps: item.externalLinkProps
              ? JSON.parse(item.externalLinkProps as any as string)
              : null,
            hidden: (item.hidden as any) === 'true',
            noNavigation: (item.noNavigation as any) === 'true',
            to: item.to ? item.to : '/',
            required: (item.required as any) === 'true',
          };

          if (resultItem.componentProps) {
            // not pretty - bool componentProps has been written both as strings and as bools in JSON.. fix it.
            'noHeader,reloadOnChange,updateOnChange,reloadOnNavigateToSelf,showSpinner,skipDialog,isDriveBrowser,noScroll,fullPageLayout,disableLightDismiss,disableUnsavedCheck,wantsLayoutChange'
              .split(',')
              .forEach((p) => {
                resultItem.componentProps[p] =
                  (resultItem.componentProps[p] as string) === 'true'
                    ? true
                    : resultItem.componentProps[p];
              });
          }

          return resultItem;
        });

        staticItems.push(...getNestedChildren(items, null));

        return staticItems;
      })
      .catch((error) => {
        console.error(
          'navigationApi.fetchMenuItems ERROR',
          { RequestUrl: COREFLOW_API + QUERY_PFX_NAVIGATION },
          error
        );
      });
  },
};

export default navigationApi;
