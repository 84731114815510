import * as React from 'react';
import { PFXWeb } from 'src/api/webApi';
import { PFXFormAlertSummary, PFXAlertColor } from 'src/api/formAlerts';
import { PFXHelpers } from 'src/utils/PFXHelpers';
import { PFXUser } from 'src/models';
import { CoreFlowAppBase } from '../CoreFlow/CoreFlowAppBase';
import { AppType, ToolType } from '../CoreFlow/CoreFlow';
import { updateFormAlerts } from 'src/redux/actionCreators';
import { PFXLocalizedTerms, PFXShared } from 'src/api/localizationApi';
import { PFXAppLayoutMode } from 'src/styling/layout';

interface Props {
  contextWeb: PFXWeb;
  contextUser: PFXUser;
  lcid: number;
  onClose?: () => void;
  onClick?: () => void;
  formAlerts?: PFXFormAlertSummary[];
  isVisible: boolean;
  terms: PFXLocalizedTerms;
  layoutMode?: PFXAppLayoutMode;
}

interface State {
  errorCount: number;
  warningCount: number;
  reminderCount: number;
}

export class AlertPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this._fetchItems = this._fetchItems.bind(this);
    this.state = { errorCount: 0, warningCount: 0, reminderCount: 0 };
  }

  componentDidMount() {
    this._fetchItems();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.formAlerts !== this.props.formAlerts) {
      if (this.props.formAlerts) {
        var errorCount = 0;
        var warningCount = 0;
        var reminderCount = 0;

        this.props.formAlerts
          .filter(alert => alert.ProjectGuid === this.props.contextWeb.guid)
          .forEach(alert => {
            errorCount += alert.ERROR;
            warningCount += alert.WARNING;
            reminderCount += alert.REMINDER;
          });
        if (
          errorCount !== this.state.errorCount ||
          warningCount !== this.state.warningCount ||
          reminderCount !== this.state.reminderCount
        ) {
          this.setState({ errorCount, warningCount, reminderCount });
        }
      }
    }

    if (
      this.props.contextWeb !== prevProps.contextWeb ||
      this.props.isVisible !== prevProps.isVisible
    ) {
      this._fetchItems();
    }
  }

  async _fetchItems() {
    // Update Data
    if (this.props.contextWeb && this.props.isVisible) {
      await Promise.all([
        window.store.dispatch(updateFormAlerts([this.props.contextWeb]))
      ]);
    }
  }

  public render() {
    return (
      (this.props.contextWeb && (
        <div className="pfx-alertpanel" id="pfx-alertpanel">
          <div className="pfx-alertpanel-header" onClick={this.props.onClick}>
            <div className="pfx-alertpanel-bubbles">
              {this.state.errorCount > 0 && (
                <div
                  key="ERRORS"
                  className="pfx-alertpanel-bubble pfx-error"
                  style={{
                    color: 'var(--pfx-theme-lightred)'
                  }}
                >
                  {this.props.terms[PFXShared.ALERTERRORS]}:{' '}
                  {this.state.errorCount}
                </div>
              )}
              {this.state.warningCount > 0 && (
                <div
                  key="WARNINGS"
                  className="pfx-alertpanel-bubble pfx-warning"
                  style={{
                    color: PFXHelpers.colors[PFXAlertColor.YELLOW]
                  }}
                >
                  {this.props.terms[PFXShared.ALERTWARNINGS]}:{' '}
                  {this.state.warningCount}
                </div>
              )}
              {false && this.state.reminderCount > 0 && (
                <div
                  key="INFOS"
                  className="pfx-alertpanel-bubble pfx-info"
                  style={{
                    color: PFXHelpers.colors[PFXAlertColor.NEUTRAL]
                  }}
                >
                  {this.props.terms[PFXShared.ALERTREMINDERS]}:{' '}
                  {this.state.reminderCount}
                </div>
              )}
            </div>
            {/* <div
              className="pfx-alertpanel-bubble pfx-ok"
              style={{ backgroundColor: PFXHelpers.colors[PFXAlertColor.GREEN] }}
            >
              3
            </div> */}{' '}
            {this.props.isVisible ? (
              <span className="pfx-alertpanel-button ms-Icon ms-Icon--ChevronDownSmall" />
            ) : (
              <span className="pfx-alertpanel-button ms-Icon ms-Icon--ChevronUpSmall" />
            )}
          </div>

          {this.props.isVisible && (
            <div className="pfx-alertpanel-scrollzone">
              <CoreFlowAppBase
                appName={AppType.Composition}
                toolName={ToolType.Composition}
                toolId={'PFX_AlertPanel'}
                contextWeb={this.props.contextWeb}
                contextUser={this.props.contextUser}
                lcid={this.props.lcid}
                fullPageLayout={true}
                dataTick={this.state.errorCount + this.state.reminderCount + this.state.warningCount}
                disableUnsavedCheck={true}
                layoutMode={this.props.layoutMode}
              />
              {/*
              <CoreFlowAppBase
                pageId={PFX_PAGE_IDS.ALERTPANEL}
                contextWeb={this.props.contextWeb}
                contextUser={this.props.contextUser}
              />
              */}
            </div>
          )}
        </div>
      )) ||
      null
    );
  }
}
