import { AppInterface } from "src/Root";

export const CloseChatActionType = "CloseChat"

export type CloseChatAction = {
    type: typeof CloseChatActionType;
};

function createCloseChatAction(): CloseChatAction {
    return {
        type: CloseChatActionType,
    };
}

export function closeChat(dispatch: typeof window.store.dispatch) {
    dispatch(createCloseChatAction())
}

export function closeChatReducer(state: AppInterface, action: CloseChatAction): AppInterface {
    return {
        ...state,
        chatVisible: false
    }
}