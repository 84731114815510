import { PFXState, history, appDebugEnabled } from 'src/Root';
import * as styling from 'src/styling/styling';

import { cfx_web_ns } from './CoreFlow';

function onFilterChange(filterMessage: cfx_web_ns.FilterMessage) {
  if (filterMessage && filterMessage.filter) {
    if (appDebugEnabled) {
      console.debug('onFilterChange', window.location.pathname, filterMessage);
    }

    const { PFX_ShowWebHistory, PFX_ShowFormHistory, PFX_ShowUserHistory } =
      filterMessage.filter;

    if (PFX_ShowWebHistory || PFX_ShowFormHistory) {
      const wide =
        (PFX_ShowWebHistory
          ? PFX_ShowWebHistory.value.toLowerCase() === 'true'
          : false) ||
        (PFX_ShowFormHistory
          ? PFX_ShowFormHistory.value.toLowerCase() === 'true'
          : false) ||
        (PFX_ShowUserHistory
          ? PFX_ShowUserHistory.value.toLowerCase() === 'true'
          : false);

      if (wide) {
        styling.updateCSSVar(
          styling.vars.pfxActionpanelWidth,
          'calc(min(100%, var(--pfx-breakpoint-desktop)))'
        );
      } else {
        const state = PFXState();
        styling.updateCSSVar(
          styling.vars.pfxActionpanelWidth,
          state.actionPanelItem
            ? state.actionPanelItem.panelWidth
            : styling.defaults.pfxActionpanelWidth
        );
      }
    }

    history.replace(
      window.location.pathname + window.location.search,
      filterMessage
    );
  }
}

export default onFilterChange;
